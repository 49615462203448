import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';
import React from 'react';

import { apologise } from 'Redux/thunks/apology';
import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';
import { confirmBefore } from 'Common/Confirmation';
import Button from 'Common/Button/Button';
import EditorActions from 'Redux/thunks/editor';
import { layoutChanged } from 'Redux/thunks/global';
import SystemActions from 'Redux/thunks/systems';
import {
  configGroupsSelector,
  DEFAULT_CONFIG_GROUP_ID,
  DEFAULT_CONFIG_GROUP_LABEL,
} from 'Redux/selectors';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import ExternalLink from 'Common/Links/ExternalLink';

import ConfigHistoryAPI from '../../api/config-history';
import { Editor, Replacements, Replacement, Configuration } from '../editor/Editor';
import ActionBar from '../action-bar';
import ConfigGroupSelector from '../config-group-selector/ConfigGroupSelector';
import JsonPreviewPanel from '../json-preview-panel/JsonPreviewPanel';

import './style.css';
import { Links } from 'Constants/links';

const LOCAL_STORAGE_KEY = 'sesam--config-editor';

/*
select
 * An editor for a system
 */
class SystemEditor extends React.Component {
  constructor(props) {
    super(props);

    this.navigateNext = () => {
      if (!this.state.compareNext) {
        this.setState({
          showComparePanel: false,
        });
      } else {
        this.setState(
          {
            compareCurrent: this.state.compareNext,
            compareNext: null,
            comparePrevious: this.state.compareCurrent,
          },
          async () => {
            const { subUrl, token } = this.props;
            const apiConf = { subUrl, token };

            try {
              let next = await ConfigHistoryAPI.get(
                apiConf,
                this.state.value._id,
                this.state.compareCurrent._updated + 1,
                1,
                false
              );

              this.setState({
                compareNext: next.length > 0 ? next[0] : null,
                showComparePanel: next.length > 0 || (next.length === 0 && !this.state.saved),
              });
            } catch (e) {
              // TODO: Apology
            }
          }
        );
      }
    };

    this.navigatePrevious = () => {
      if (!this.state.showComparePanel && !this.state.saved) {
        this.setState({
          showComparePanel: true,
        });
      } else {
        this.setState(
          {
            showComparePanel: true,
            compareCurrent: this.state.comparePrevious,
            compareNext: this.state.compareCurrent,
            comparePrevious: null,
          },
          async () => {
            const { subUrl, token } = this.props;
            const apiConf = { subUrl, token };

            try {
              let previous = await ConfigHistoryAPI.get(
                apiConf,
                this.state.value._id,
                this.state.compareCurrent._updated,
                1,
                true
              );

              this.setState({
                comparePrevious: previous.length > 0 ? previous[0] : null,
              });
            } catch (e) {
              // TODO: Apology
            }
          }
        );
      }
    };

    this.onConfigChanged = (config) => {
      this.props.updateDirtyState(true);
      this.setState({
        value: config,
        saved: false,
      });
    };

    this.onTemplateChanged = (selection) => {
      this.setState({ selectedTemplate: selection });
    };

    this.onReplaceTemplate = (ev) => {
      ev.preventDefault();
      if (this.props.isDirty) {
        confirmBefore('This will replace the config with a new template', () =>
          this.replaceConfig(this.state.selectedTemplate)
        );
      } else {
        this.replaceConfig(this.state.selectedTemplate);
      }
    };

    this.onSave = () => {
      const { apologise, updateDirtyState, onSave, onAfterSave } = this.props;

      const validJson = this.state.validJson;
      const hasId = this.state.value._id ? true : false;

      if (validJson && hasId) {
        const system = this.state.value;
        this.setState({
          saving: true,
        });
        onSave(system).then(async () => {
          updateDirtyState(false);
          this.setState({
            saving: false,
            saved: true,
          });

          if (!this.state.showComparePanel) {
            this.resetConfigHistory();
          } else if (!this.state.compareNext) {
            const { subUrl, token } = this.props;
            const apiConf = { subUrl, token };

            try {
              let next = await ConfigHistoryAPI.get(
                apiConf,
                this.state.value._id,
                this.state.compareCurrent._updated + 1,
                1,
                false
              );

              this.setState({
                compareNext: next.length > 0 ? next[0] : null,
              });
            } catch (e) {
              console.log(e);
            }
          }

          onAfterSave(system);
        });
      } else if (!validJson) {
        apologise('The json contains errors and cannot be saved');
      } else if (!hasId) {
        apologise('The _id value cannot be null or undefined');
      }
    };

    this.replaceConfig = (selection) => {
      const value = this.state.systemTemplates.find((template) => template.name === selection);
      this.props.updateDirtyState(true);
      this.setState({
        editorKey: this.state.editorKey + 1,
        value: value.config,
        configError: false,
      });
    };

    this.resetConfigHistory = () => {
      this.setState(
        {
          showComparePanel: false,
          compareCurrent: null,
          compareNext: null,
          comparePrevious: null,
          currentConfigEntity: null,
        },
        async () => {
          const { subUrl, token } = this.props;
          const apiConf = { subUrl, token };

          try {
            const current = await ConfigHistoryAPI.get(
              apiConf,
              this.state.value._id,
              null,
              1,
              true
            );

            let previous = await ConfigHistoryAPI.get(
              apiConf,
              this.state.value._id,
              current[0]._updated,
              1,
              true
            );

            this.setState({
              compareCurrent: current.length > 0 ? current[0] : null,
              comparePrevious: previous.length > 0 ? previous[0] : null,
              currentConfigEntity: current.length > 0 ? current[0] : null,
            });
          } catch (e) {
            this.setState({
              compareCurrent: null,
              comparePrevious: null,
              compareNext: null,
              currentConfigEntity: null,
            });
          }
        }
      );
    };

    this.toggleEffectiveConfig = () => {
      this.setState({
        showEffectiveConfig: !this.state.showEffectiveConfig,
      });
    };

    this.saveSelectedConfigGroupToLS = (configGroup) => {
      setIntoLocalStorage(LOCAL_STORAGE_KEY, ['configGroup'], configGroup);
    };

    this.loadConfigGroupFromLS = () => {
      const configGroupFromLS = getFromLocalStorage(
        LOCAL_STORAGE_KEY,
        ['configGroup'],
        DEFAULT_CONFIG_GROUP_ID
      );

      if (!this.props.configGroups.includes(configGroupFromLS)) {
        this.saveSelectedConfigGroupToLS(DEFAULT_CONFIG_GROUP_ID);
        return DEFAULT_CONFIG_GROUP_ID;
      }
      return configGroupFromLS;
    };

    // eslint-disable-next-line no-unused-vars
    this.onReplaceConfigGroup = (ev) => {
      if (this.props.isDirty) {
        confirmBefore('This will modify the config group metadata', () =>
          this._replaceConfigGroup()
        );
      } else {
        this._replaceConfigGroup();
      }
    };

    this.onConfigGroupChanged = (selection) => {
      this.setState({ selectedConfigGroup: selection });
    };

    this._updateStateAfterReplace = (editorKey, value) => {
      this.props.updateDirtyState(true);
      this.setState({
        editorKey: editorKey + 1,
        value,
        saved: false,
      });
    };

    this._replaceConfigGroup = () => {
      const value = cloneDeep(this.state.value);
      let newConfigGroup = this.state.selectedConfigGroup;
      if (
        newConfigGroup === DEFAULT_CONFIG_GROUP_ID ||
        newConfigGroup === DEFAULT_CONFIG_GROUP_LABEL
      ) {
        // if default group, then just remove
        // the $config-group metadata property
        if (value.metadata) {
          delete value.metadata['$config-group'];
        }

        if (isEmpty(value.metadata)) {
          // also delete the value.metadata property
          // if we emptied it
          delete value.metadata;
        }
      } else {
        if (!value.metadata) {
          value.metadata = { '$config-group': this.state.selectedConfigGroup };
        } else if (isObject(value.metadata)) {
          value.metadata['$config-group'] = this.state.selectedConfigGroup;
        }
      }
      this.saveSelectedConfigGroupToLS(newConfigGroup);
      this._updateStateAfterReplace(this.state.editorKey, value);
    };

    this.state = {
      configError: false,
      editorKey: 1,
      selectedTemplate: undefined,
      systemTemplates: [],
      validJson: true,
      value: this.props.initialValue,
      saved: this.props.initialSaved,
      saving: false,
      showEffectiveConfig: false,
      selectedConfigGroup: this.loadConfigGroupFromLS(),

      showComparePanel: false,
      compareCurrent: null,
      compareNext: null,
      comparePrevious: null,
      currentConfigEntity: null,
    };
  }

  componentDidMount() {
    this.resetConfigHistory();

    this.props.getSystemPrototypes().then((systemTemplates) =>
      this.setState({
        systemTemplates: systemTemplates.filter(
          (t) => get(t, 'config.type', '') !== 'system:sesam-node'
        ),
      })
    );

    this.unsubscribeFromRouter = (() => {
      let isDone = false;
      return this.props.router.listenBefore(() => {
        if (!isDone) {
          if (this.props.isDirty || !this.state.validJson) {
            const isDone = confirm(
              "You haven't saved yet, or your configuration contains errors. All changes will be lost. Are you sure you want to exit?"
            );
            if (isDone) {
              this.props.updateDirtyState(false);
            }
            return isDone;
          }
        }
      });
    })();
  }

  componentWillUnmount() {
    this.unsubscribeFromRouter();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.initialValue, this.props.initialValue)) {
      this.setState({
        editorKey: this.state.editorKey + 1,
        value: this.props.initialValue,
      });
    }
  }

  render() {
    const templates = this.state.systemTemplates
      .map((template) => template.name)
      .filter((name) => name !== 'fake prototype');

    const effectiveConfigButton = (
      <Button
        active={this.state.showEffectiveConfig}
        key="effectiveConfig"
        onClick={this.toggleEffectiveConfig}
      >
        Effective config
      </Button>
    );

    const effectiveConfig = get(this.props.currentSystem, 'config.effective');

    return (
      <React.Fragment>
        <Editor
          disableSave={!this.state.validJson}
          message={this.props.message}
          onSave={this.onSave}
          shortMessage={this.props.shortMessage}
          status={this.props.status}
          extraToolbarPanelButtons={[effectiveConfigButton]}
        >
          <Replacements>
            <Replacement
              title="Config group"
              onReplaceTemplate={this.onReplaceConfigGroup}
              disabled={!this.state.selectedConfigGroup}
            >
              <ConfigGroupSelector
                configGroups={this.props.configGroups}
                onChangeConfigGroup={this.onConfigGroupChanged}
                onSelectConfigGroup={this.onConfigGroupChanged}
                selectedConfigGroup={this.state.selectedConfigGroup}
              />
            </Replacement>
            <Replacement
              title="System type"
              onReplaceTemplate={this.onReplaceTemplate}
              disabled={!this.state.selectedTemplate}
            >
              <SesamTextField
                label="Choose template"
                id="systemTemplate"
                onChange={(ev) => this.onTemplateChanged(ev.target.value)}
                value={this.state.selectedTemplate}
                select
              >
                {templates.map((template) => (
                  <SesamMenuItem key={template} value={template}>
                    {template}
                  </SesamMenuItem>
                ))}
              </SesamTextField>
            </Replacement>
          </Replacements>
          <Configuration
            configError={this.state.configError}
            key={this.state.editorKey}
            onChange={this.onConfigChanged}
            onSave={this.onSave}
            onValidateJson={(validJson) => this.setState({ validJson })}
            value={this.state.value}
            configHash={get(this.props.currentSystem, 'config_hash')}
            compare={this.state.showComparePanel}
            compareValue={this.state.compareCurrent}
            currentConfigEntity={this.state.currentConfigEntity}
            saved={this.state.saved}
          >
            {this.props.children}
          </Configuration>
          {this.state.showEffectiveConfig && <JsonPreviewPanel json={effectiveConfig} />}
        </Editor>
        <div className="system-editor__action-bar">
          {!this.props.showDataView && (
            <React.Fragment>
              <span style={{ fontSize: '0.75rem', margin: '1rem 0.5rem 0 0' }}>
                Config history:{' '}
              </span>
              <Button
                onClick={this.navigatePrevious}
                disabled={
                  (!this.state.comparePrevious && this.state.saved) ||
                  (this.state.showComparePanel && !this.state.comparePrevious)
                }
              >
                Previous
              </Button>
              <Button
                onClick={this.navigateNext}
                disabled={
                  !this.state.showComparePanel || (!this.state.compareNext && this.state.saved)
                }
              >
                Next
              </Button>
              <Button
                onClick={this.resetConfigHistory}
                disabled={this.state.showComparePanel === false}
              >
                Back to latest
              </Button>
            </React.Fragment>
          )}

          <ActionBar
            message={this.props.message}
            shortMessage={this.props.shortMessage}
            status={this.props.status}
          >
            <ExternalLink
              href={Links.QuickReferenceDocumentation}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 'auto' }}
            >
              DTL Reference
            </ExternalLink>

            <Button
              onClick={this.onSave}
              disabled={
                !this.state.validJson || (!this.props.isDirty && !this.props.isDuplicatedSystem)
              }
            >
              Save
            </Button>
          </ActionBar>
        </div>
      </React.Fragment>
    );
  }
}

SystemEditor.propTypes = {
  children: PropTypes.node,
  getSystemPrototypes: PropTypes.func.isRequired,
  initialDirty: PropTypes.bool,
  initialSaved: PropTypes.bool,
  initialValue: PropTypes.object.isRequired,
  isDirty: PropTypes.bool,
  isDuplicatedSystem: PropTypes.bool,
  message: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onAfterSave: PropTypes.func,
  shortMessage: PropTypes.string,
  status: PropTypes.string,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  configGroups: PropTypes.arrayOf(PropTypes.string),
  updateDirtyState: PropTypes.func,
  apologise: PropTypes.func,
  router: PropTypes.shape({ listenBefore: PropTypes.func }),
  currentSystem: PropTypes.object,
  showDataView: PropTypes.bool,
};

SystemEditor.defaultProps = {
  onAfterSave: () => null,
};

const mapStateToProps = (state, ownProps) => ({
  currentSystem: state.systems[ownProps.initialValue._id],
  isDirty: state.editor.isDirty,
  subUrl: state.subscription.url,
  token: state.subscription.token,
  configGroups: configGroupsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateDirtyState: (isDirty) => dispatch(EditorActions.dirtyStateUpdated(isDirty)),
  getSystemPrototypes: () => dispatch(SystemActions.getSystemPrototypes()),
  layoutChange: () => dispatch(layoutChanged()),
  apologise: (msg) => dispatch(apologise(msg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemEditor));
