import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ConfigHistoryAPI from '../../api/config-history';
import ModelAPI from '../../api/models';
import PipeTypesAPI from '../../api/pipe-types';
import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';
import { getSourceDatasetIds, getUpstreamPipeFromDatasetId } from 'Internals/pipes';
import { propertiesToSchema } from 'Internals/utils';
import EditorActions from 'Redux/thunks/editor';
import {
  configGroupsSelector,
  DEFAULT_CONFIG_GROUP_ID,
  DEFAULT_CONFIG_GROUP_LABEL,
} from 'Redux/selectors';
import { apologise } from 'Redux/thunks/apology';
import ActionBar from '../action-bar';
import Button from 'Common/Button/Button';
import { confirmBefore } from 'Common/Confirmation';
import { Form, FormActions } from 'Common/forms';
import JsonPanel from 'Common/JsonPanel';
import SesamModal from 'Common/SesamModal/SesamModal';
import { LinkButton } from 'Common/LinkButton/LinkButton';
import { LoadingPanel } from 'Common/LoadingPanel';
import SesamSlider from 'Common/SesamSlider/SesamSlider';
import ConfigGroupSelector from '../config-group-selector/ConfigGroupSelector';
import DataView from '../data-view/DataView';
import DTLDebuggerPanel from '../dtl-debugger-panel/DTLDebuggerPanel';
import { Configuration, Editor, Replacement, Replacements } from '../editor/Editor';
import { layoutChanged } from 'Redux/thunks/global';
import PipeActions from 'Redux/thunks/pipes';
import PipeAnalysePanel from '../pipe-analyse-panel';
import GenerateSchemaPanelContainer from '../generate-schema-panel/GenerateSchemaPanelContainer';
import PipePreviewPanel from '../pipe-preview-panel';
import SystemActions from 'Redux/thunks/systems';
import JsonPreviewPanel from '../json-preview-panel/JsonPreviewPanel';
import SesamRadioGroupField from 'Common/SesamRadioGroupField/SesamRadioGroupField';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import ExternalLink from 'Common/Links/ExternalLink';
import { Links } from 'Constants/links';

import './style.css';
import { TargetSchemaPanel } from '../pipe-target-schema-panel/TargetSchemaPanel';
import { DatasetInspectorFilters } from 'Types/enums';

const LOCAL_STORAGE_KEY = 'sesam--config-editor';

/*
 * An editor for a pipe
 */
// TODO baard: schedule template and cron-syntax-switch
// TODO baard: disable replacement when no valid selection
class PipeEditor extends React.Component {
  constructor(props) {
    super(props);

    this.onConfigChanged = (config) => {
      this.props.updateDirtyState(true);
      this.setState({
        value: config,
        saved: false,
      });
      if (this.state.showPreview) {
        this.setState({ outputStale: true });
      }
    };

    this.onSourceSystemSelected = (selection) => {
      this.setState({ selectedSourceSystem: selection });
      if (!selection) {
        this.setState({ sourceTemplates: [], selectedSourceTemplate: null });
      } else {
        this.props
          .getSourcePrototypes(selection)
          .then((sourceTemplates) => this.setState({ sourceTemplates }))
          .catch((err) => this.setState({ sourceTemplates: [] }));
      }
    };

    this.onSourceTemplateChanged = (selection) => {
      this.setState({ selectedSourceTemplate: selection });
    };

    this.resetConfigHistory = () => {
      this.setState(
        {
          showComparePanel: false,
          compareCurrent: null,
          compareNext: null,
          comparePrevious: null,
          currentConfigEntity: null,
        },
        async () => {
          const { subUrl, token } = this.props;
          const apiConf = { subUrl, token };

          try {
            const current = await ConfigHistoryAPI.get(
              apiConf,
              this.state.value._id,
              null,
              1,
              true
            );

            const previous = await ConfigHistoryAPI.get(
              apiConf,
              this.state.value._id,
              current[0]._updated,
              1,
              true
            );

            this.setState({
              compareCurrent: current.length > 0 ? current[0] : null,
              comparePrevious: previous.length > 0 ? previous[0] : null,
              currentConfigEntity: current.length > 0 ? current[0] : null,
            });
          } catch (e) {
            this.setState({
              compareCurrent: null,
              comparePrevious: null,
              compareNext: null,
              currentConfigEntity: null,
            });
          }
        }
      );
    };

    /**
     * Get the source schema for pipe to be used in the autocompletion
     * This method will first try to use the Models API to get the source entity-types
     * for this pipe. If that fails or returns no results, it tries the sink entity-types
     * of it's upstream pipe, if there is one. If that fails or returns no properties
     * it falls back to the old generate schema API
     * @returns {Promise<void>}
     */
    this.getSourceSchema = async () => {
      if (this.props.isNewPipe) {
        return;
      }
      const { subUrl, token, pipe, pipes, upstreams } = this.props;
      // Flags that control the flow in this method
      let useOldGenerateApi = false;
      let useUpstreamSink = false;

      // Request options are the same
      const requestOptions = {
        credentials: 'include',
        headers: { Authorization: `bearer ${token}` },
      };

      const sourceDatasetIds = getSourceDatasetIds(pipe);
      const sourceDatasetID =
        !isUndefined(sourceDatasetIds) && sourceDatasetIds.length === 1 ? sourceDatasetIds[0] : '';
      const upstreamPipe = getUpstreamPipeFromDatasetId(sourceDatasetID, pipes, upstreams);
      const apiConf = {
        token,
        subUrl,
      };

      // First, let's try the source of this pipe's entity types
      let schema = [];
      try {
        let response = await PipeTypesAPI.getSourceEntityType(apiConf, pipe._id);
        if (response.properties && !isEmpty(response.properties)) {
          // populate the schema array like when using the old API
          schema = propertiesToSchema(response.properties, 'source');
        } else {
          // we found nothing, we try the upstream sink entity types
          useUpstreamSink = true;
        }
      } catch (e) {
        // error getting properties, lets try the upstream sink entity types
        useUpstreamSink = true;
      }
      // if that didn't work, try upstream sink entity types
      if (useUpstreamSink) {
        if (upstreamPipe) {
          schema = [];
          try {
            let response = await PipeTypesAPI.getSinkEntityType(apiConf, upstreamPipe._id);
            if (response.properties && !isEmpty(response.properties)) {
              schema = propertiesToSchema(response.properties, 'source');
            } else {
              // we found nothing, we fall back to old API
              useOldGenerateApi = true;
            }
          } catch (e) {
            // error getting properties, fall back to old API
            useOldGenerateApi = true;
          }
        } else {
          // no upstream pipe, fall back to old API
          useOldGenerateApi = true;
        }
      }

      if (useOldGenerateApi) {
        if (upstreamPipe) {
          schema = [];
          try {
            schema = await PipeTypesAPI.getSchemaDefinition(apiConf, upstreamPipe._id);
          } catch (e) {
            console.log(e);
            this.setState({
              loading: false,
            });
            return;
          }
        }
      }
      this.setState({
        sourceSchema: schema,
      });
    };

    this.getTargetSchemaIds = async () => {
      const { subUrl, token, pipe } = this.props;
      if (!pipe) {
        return;
      }
      const apiConf = { subUrl, token };

      // // First, try and get the sink schema
      // let sinkSchema = {};
      // try {
      //   sinkSchema = await PipeAPI.getSinkEntityTypes(apiConf, pipe._id);
      // } catch (e) {}

      // Get all the entity types from non-verbose response from the models API
      let models = [];
      try {
        models = await ModelAPI.getAll(apiConf, true, false);
      } catch (e) {}

      const allEntityTypeIds = {}; // using dictionary as set
      for (const m of models) {
        const modelEntityTypes = m['entity_types'];
        if (modelEntityTypes && isObject(modelEntityTypes)) {
          for (const entityTypeId of Object.keys(modelEntityTypes)) {
            allEntityTypeIds[entityTypeId] = true;
          }
        }
      }

      const savedTargetSchemaId = this.loadSelectedTargetSchemaIdFromLS();

      let targetSchema = null;
      if (!isEmpty(allEntityTypeIds)) {
        let schemaId = null;
        if (savedTargetSchemaId) {
          schemaId = savedTargetSchemaId;
        } else {
          schemaId = pipe._id;
        }
        const hasPipeSinkEntityType = allEntityTypeIds[schemaId];
        if (hasPipeSinkEntityType) {
          try {
            targetSchema = await PipeTypesAPI.getSinkEntityType(apiConf, schemaId);
          } catch (e) {}
        }
      }

      this.setState({
        targetSchemaIds: Object.keys(allEntityTypeIds),
        selectedTargetSchema: targetSchema,
      });
    };

    this.onSelectedTargetSchemaChanged = async (id) => {
      let targetSchema = null;
      const { subUrl, token } = this.props;
      const apiConf = { subUrl, token };

      if (id === null) {
        this.setState({ selectedTargetSchema: null }, () => {
          this.saveSelectedTargetSchemaIdToLS(null);
        });
        return;
      }

      try {
        targetSchema = await PipeTypesAPI.getSinkEntityType(apiConf, id);
      } catch (e) {}

      if (targetSchema) {
        this.setState(
          {
            selectedTargetSchema: targetSchema,
          },
          () => {
            this.saveSelectedTargetSchemaIdToLS(id);
          }
        );
      }
    };

    this.loadSelectedTargetSchemaIdFromLS = () => {
      const targetSchemaIdFromLS = getFromLocalStorage(
        LOCAL_STORAGE_KEY,
        [this.props.pipe._id, 'targetSchemaId'],
        null
      );
      return targetSchemaIdFromLS;
    };

    this.saveSelectedTargetSchemaIdToLS = (id) => {
      setIntoLocalStorage(LOCAL_STORAGE_KEY, [this.props.pipe._id, 'targetSchemaId'], id);
    };

    this.saveSelectedConfigGroupToLS = (configGroup) => {
      setIntoLocalStorage(LOCAL_STORAGE_KEY, ['configGroup'], configGroup);
    };

    this.loadConfigGroupFromLS = () => {
      const configGroupFromLS = getFromLocalStorage(
        LOCAL_STORAGE_KEY,
        ['configGroup'],
        DEFAULT_CONFIG_GROUP_ID
      );

      if (!this.props.configGroups.includes(configGroupFromLS)) {
        this.saveSelectedConfigGroupToLS(DEFAULT_CONFIG_GROUP_ID);
        return DEFAULT_CONFIG_GROUP_ID;
      }

      return configGroupFromLS;
    };

    this.onReplaceSourceTemplate = (ev) => {
      console.log(ev);
      ev.preventDefault();
      if (this.props.isDirty) {
        confirmBefore('This will replace the source part of the config with a new template', () =>
          this._replaceSourceConfig()
        );
      } else {
        this._replaceSourceConfig();
      }
    };

    this.onReplaceConfigGroup = (ev) => {
      if (this.props.isDirty) {
        confirmBefore('This will modify the config group metadata', () =>
          this._replaceConfigGroup()
        );
      } else {
        this._replaceConfigGroup();
      }
    };

    this.onSinkSystemSelected = (selection) => {
      this.setState({ selectedSinkSystem: selection });
      if (!selection) {
        this.setState({ sinkTemplates: [], selectedSinkTemplate: null });
      } else {
        this.props
          .getSinkPrototypes(selection)
          .then((sinkTemplates) => this.setState({ sinkTemplates }))
          .catch((err) => this.setState({ sinkTemplates: [] }));
      }
    };

    this.onSinkTemplateChanged = (selection) => {
      this.setState({ selectedSinkTemplate: selection });
    };

    this.onConfigGroupChanged = (selection) => {
      this.setState({ selectedConfigGroup: selection });
    };

    this.onReplaceSinkTemplate = (ev) => {
      ev.preventDefault();
      if (this.props.isDirty) {
        confirmBefore('This will replace the sink part of the config with a new template', () =>
          this._replaceSinkConfig()
        );
      } else {
        this._replaceSinkConfig();
      }
    };

    this.onReplaceSinkSchema = (ev) => {
      ev.preventDefault();
      this.fetchSinkSchema(this.state.sinkSchemaSampleSize)
        .then((generatedSinkSchema) => this.setState({ generatedSinkSchema }))
        .then(this.replaceSinkSchema);
    };

    this.onScheduleChanged = (selection) => {
      this.setState({ schedule: selection });
    };

    this.onReplaceSchedule = (ev) => {
      ev.preventDefault();
      if (this.props.isDirty) {
        confirmBefore('This will replace the schedule part of the config with a new template', () =>
          this._replaceScheduleConfig()
        );
      } else {
        this._replaceScheduleConfig();
      }
    };

    this.onSave = () => {
      const { onSave, updateDirtyState, onAfterSave, apologise } = this.props;

      const validJson = this.state.validJson;
      const hasId = this.state.value._id ? true : false;

      if (validJson && hasId) {
        const pipe = this.state.value;
        this.setState({
          saving: true,
        });
        onSave(pipe).then(async () => {
          updateDirtyState(false);
          this.setState({
            saving: false,
            saved: true,
          });

          if (!this.state.showComparePanel) {
            this.resetConfigHistory();
          } else if (!this.state.compareNext) {
            const { subUrl, token } = this.props;
            const apiConf = { subUrl, token };

            try {
              let next = await ConfigHistoryAPI.get(
                apiConf,
                this.state.value._id,
                this.state.compareCurrent._updated + 1,
                1,
                false
              );

              this.setState({
                compareNext: next.length > 0 ? next[0] : null,
              });
            } catch (e) {
              console.log(e);
            }
          }

          onAfterSave(pipe);
        });
      } else if (!validJson) {
        apologise('The json contains errors and cannot be saved');
      } else if (!hasId) {
        apologise('The _id value cannot be null or undefined');
      }
    };

    this._replaceSourceConfig = () => {
      const value = cloneDeep(this.state.value);
      value.source = this.state.sourceTemplates.find(
        (source) => source.name === this.state.selectedSourceTemplate
      ).config;
      this._updateStateAfterReplace(this.state.editorKey, value);
    };

    this._replaceSinkConfig = () => {
      const value = cloneDeep(this.state.value);
      value.sink = this.state.sinkTemplates.find(
        (source) => source.name === this.state.selectedSinkTemplate
      ).config;
      this._updateStateAfterReplace(this.state.editorKey, value);
    };

    this._replaceScheduleConfig = () => {
      const value = cloneDeep(this.state.value);
      if (!value.pump) {
        value.pump = {};
      }
      delete value.pump.cron_expression;
      delete value.pump.schedule_interval;
      assign(value.pump, this._getSchedule(this.state.schedule));
      this._updateStateAfterReplace(this.state.editorKey, value);
    };

    this._replaceConfigGroup = () => {
      const value = cloneDeep(this.state.value);
      let newConfigGroup = this.state.selectedConfigGroup;
      if (
        newConfigGroup === DEFAULT_CONFIG_GROUP_ID ||
        newConfigGroup === DEFAULT_CONFIG_GROUP_LABEL
      ) {
        // if default group, then just remove
        // the $config-group metadata property
        if (value.metadata) {
          delete value.metadata['$config-group'];
        }

        if (isEmpty(value.metadata)) {
          // also delete the value.metadata property
          // if we emptied it
          delete value.metadata;
        }
      } else {
        if (!value.metadata) {
          value.metadata = { '$config-group': this.state.selectedConfigGroup };
        } else if (isObject(value.metadata)) {
          value.metadata['$config-group'] = this.state.selectedConfigGroup;
        }
      }
      this.saveSelectedConfigGroupToLS(newConfigGroup);
      this._updateStateAfterReplace(this.state.editorKey, value);
    };

    this._updateStateAfterReplace = (editorKey, value) => {
      this.props.updateDirtyState(true);
      this.setState({
        editorKey: editorKey + 1,
        value,
        saved: false,
      });
    };

    this._getSchedule = (schedule) => {
      const cron = (pattern) => ({ cron_expression: pattern });
      switch (schedule) {
        case 'hourly':
          return cron('0 * * * ?');
        case 'custom':
          return { schedule_interval: 30 };
        case 'daily':
          return cron('0 0 * * ?');
        case 'weekly':
          return cron('0 0 ? * SUN');
        case 'monthly':
          return cron('0 0 1 * ?');
        case 'yearly':
          return cron('0 0 1 1 ?');
        default:
          return {};
      }
    };

    this.fetchSinkSchema = (sampleSize) => {
      let keysOnly = null;

      if (get(this.state.value, 'sink.type') === 'csv_endpoint') {
        keysOnly = true;
      }

      this.setState({ fetchingGeneratedSchema: true });

      return PipeTypesAPI.getSchemaDefinition(
        { subUrl: this.props.subUrl, token: this.props.token },
        this.state.value._id,
        sampleSize,
        keysOnly
      )
        .then((generatedSchema) => {
          this.setState({ fetchingGeneratedSchema: false });
          return generatedSchema;
        })
        .catch((e) => {
          this.setState({
            fetchingGeneratedSchema: false,
          });
          this.props.apologise(e);
        });
    };

    this.fetchSinkSchemaFreeSampleSize = () => {
      this.setState({ generatedSinkSchema: null });
      this.fetchSinkSchema(this.state.sinkSchemaFreeSampleSize).then((generatedSinkSchema) =>
        this.setState({ generatedSinkSchema })
      );
    };

    this.replaceSinkSchema = () => {
      const doIt = () => {
        const newValue = cloneDeep(this.state.value);
        const sinkType = get(this.state.value, 'sink.type');

        newValue.sink = cloneDeep(this.state.value.sink);

        if (sinkType === 'sql') {
          newValue.sink.schema_definition = this.state.generatedSinkSchema;
        } else if (sinkType === 'csv_endpoint') {
          newValue.sink.columns = this.state.generatedSinkSchema;
        }

        this.setState({
          value: newValue,
          editorKey: this.state.editorKey + 1,
          generatedSinkSchema: null,
        });
        this.closeSchemaDialog();
      };

      if (get(this.state, 'value.sink.schema_definition')) {
        confirmBefore('This will replace the target sink schema definition', doIt);
      } else {
        doIt();
      }
    };

    this.onAnalyze = () => {
      if (this.state.showAnalysis) {
        this.setState(
          {
            showAnalysis: false,
            errors: '',
          },
          this.props.layoutChange
        );
      } else {
        this.setState(
          {
            showAnalysis: true,
            errors: '',
            analysisReady: false,
            startingAnalysis: true,
          },
          this.props.layoutChange
        );
        clearTimeout(this.runTimeout);

        this.props
          .analysePipe(this.state.value)
          .then((analysis) =>
            this.setState({
              analysis,
              analysisReady: true,
              startingAnalysis: false,
            })
          )
          .catch((err) =>
            this.setState({
              errors: err.responseBody,
              startingAnalysis: false,
              analysisReady: true,
              analysis: undefined,
            })
          );
      }
    };

    this.onGenerateSchema = () => {
      if (this.state.showGeneratedSchemaPanel) {
        this.setState({
          showGeneratedSchemaPanel: false,
        });
      } else {
        this.setState({
          showGeneratedSchemaPanel: true,
        });
      }
    };

    this.toggleEffectiveConfig = () => {
      this.setState({
        showEffectiveConfig: !this.state.showEffectiveConfig,
      });
    };

    this.toggleDebug = () => {
      if (this.state.showDebugger) {
        this.previewTraceInfo = {};
        this.setState({
          showPreview: true,
          showDebugger: false,
        });
      } else {
        let customSourceEntity =
          this.state.sourceData && !isEmpty(this.state.sourceData)
            ? this.state.sourceData
            : this.state.sampleData;
        customSourceEntity = { _trace: true, ...customSourceEntity };
        const pipe = this.state.value;

        this.props.previewPipe(pipe, customSourceEntity, true).then((result) => {
          this.previewTraceInfo = {
            trace: get(result, 'transformed[0][0]._trace-info.trace'),
            id: get(result, 'transformed[0][0]._id'),
          };
          if (isUndefined(this.previewTraceInfo.trace)) {
            this.props.apologise('Trace info unavailable.');
            return;
          }
          this.setState({
            showPreview: false,
            showDebugger: true,
          });
        });
      }
    };

    this.togglePreview = () => {
      if (this.state.showPreview) {
        this.setState(
          {
            showPreview: false,
            errors: '',
          },
          this.props.layoutChange
        );
      } else {
        this.onPreview();
      }
    };

    this.navigateNext = () => {
      if (!this.state.compareNext) {
        this.setState({
          showComparePanel: false,
        });
      } else {
        this.setState(
          {
            compareCurrent: this.state.compareNext,
            compareNext: null,
            comparePrevious: this.state.compareCurrent,
          },
          async () => {
            const { subUrl, token } = this.props;
            const apiConf = { subUrl, token };

            try {
              let next = await ConfigHistoryAPI.get(
                apiConf,
                this.state.value._id,
                this.state.compareCurrent._updated + 1,
                1,
                false
              );

              this.setState({
                compareNext: next.length > 0 ? next[0] : null,
                showComparePanel: next.length > 0 || (next.length === 0 && !this.state.saved),
              });
            } catch (e) {
              // TODO: Apology
            }
          }
        );
      }
    };

    this.navigatePrevious = () => {
      if (!this.state.showComparePanel && !this.state.saved) {
        this.setState({
          showComparePanel: true,
        });
      } else {
        this.setState(
          {
            showComparePanel: true,
            compareCurrent: this.state.comparePrevious,
            compareNext: this.state.compareCurrent,
            comparePrevious: null,
          },
          async () => {
            const { subUrl, token } = this.props;
            const apiConf = { subUrl, token };

            try {
              let previous = await ConfigHistoryAPI.get(
                apiConf,
                this.state.value._id,
                this.state.compareCurrent._updated,
                1,
                true
              );

              this.setState({
                comparePrevious: previous.length > 0 ? previous[0] : null,
              });
            } catch (e) {
              // TODO: Apology
            }
          }
        );
      }
    };

    this.onPreview = () => {
      this.setState(
        {
          showPreview: true,
          errors: '',
          startingRun: true,
          outputReady: false,
          outputInProgress: false,
          outputStale: false,
        },
        this.props.layoutChange
      );

      clearTimeout(this.runTimeout);
      this.dispatchRun();
    };

    this.dispatchRun = () => {
      const pipe = this.state.value;
      let fakeSource = false;
      let customSourceEntity;
      if (this.state.sourceData && !isEmpty(this.state.sourceData)) {
        fakeSource = true;
        customSourceEntity = this.state.sourceData;
      }

      this.props
        .previewPipe(pipe, customSourceEntity, true)
        .then((result) => {
          return this.handlePreviewResult(result, fakeSource);
        })
        .catch((err) =>
          this.setState({
            previewErrors: err.responseBody.detail || 'Error while attempting preview',
            startingRun: false,
            outputReady: true,
            outputInProgress: false,
            output: undefined,
          })
        );
    };

    this.handleFieldChange = (ev) => {
      this.setState({ [ev.target.id]: ev.target.value });
    };

    this.handleSampleSizeChange = (val) => {
      this.setState({ sinkSchemaSampleSize: val });
    };

    this.handlePreviewResult = (result, fakeSource) => {
      if (result.error) {
        // we fake the structure to fit into ErrorPanel
        this.setState({
          previewErrors: result.error,
          startingRun: false,
          outputReady: true,
          outputInProgress: false,
          output: null,
        });
      } else {
        this.setState({
          previewErrors: null,
          sampleData: fakeSource ? this.state.sampleData : result.source,
          startingRun: false,
          outputReady: result['is-ready'],
          outputInProgress: result['in-progress'],
          outputStale: false,
          output: result.sink,
          transformed: result.transformed,
        });

        if (result['in-progress']) {
          this.runTimeout = setTimeout(this.dispatchRun, 1500);
        }
      }
    };

    this.onSourceChanged = (sourceData) => {
      this.setState({ sourceData });
    };

    this.onRevertSource = () => {
      this.setState(
        {
          sourceData: null,
          revertCount: this.state.revertCount + 1,
        },
        () => {
          this.onPreview();
        }
      );
    };

    this.onHandleEntityFetched = (entity) => {
      this.setState(
        {
          sourceData: entity,
          revertCount: this.state.revertCount + 1,
        },
        () => {
          this.onPreview();
        }
      );
    };

    this.onAddDtlTransform = () => {
      const copy = cloneDeep(this.state.value);
      const template = {
        type: 'dtl',
        rules: {
          default: [['copy', '_id']],
        },
      };
      if (!copy.transform) {
        // just set the transform
        copy.transform = template;
      } else if (isArray(copy.transform)) {
        // push another dtl transform at the end
        copy.transform.push(template);
      } else {
        // replace existing transform with array and add dtl transform at end
        copy.transform = [copy.transform, template];
      }

      this.props.updateDirtyState(true);
      this.setState({
        editorKey: this.state.editorKey + 1,
        value: copy,
        saved: false,
      });
    };

    this.closeSchemaDialog = () => {
      this.setState({ showSchemaDialog: false });
    };

    this.state = {
      value: this.props.initialValue,
      sourceTemplates: [],
      sinkTemplates: [],
      selectedSourceSystem: null,
      selectedSourceTemplate: null,
      selectedSinkSystem: null,
      selectedSinkTemplate: null,
      selectedHopTemplate: null,
      selectedConfigGroup: this.loadConfigGroupFromLS(),

      showAnalysis: false,
      analysisReady: false,
      lastEntityFetched: null,
      outputReady: true,
      outputInProgress: false,
      revertCount: 0,
      startingAnalysis: false,
      startingRun: false,
      sourceData: null,

      showPreview: false,
      showGeneratedSchemaPanel: false,
      showEffectiveConfig: false,

      validJson: true,
      editorKey: 1,

      sinkSchemaSampleSize: '500',
      sinkSchemaFreeSampleSize: '2000',
      generatedSinkSchema: null,

      saved: this.props.initialSaved,
      saving: false,
      targetSchemaIds: [],
      selectedTargetSchema: null,
      sourceSchema: null,
      configHash: null,

      showComparePanel: false,
      compareCurrent: null,
      compareNext: null,
      comparePrevious: null,
      currentConfigEntity: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pipes !== this.props.pipes || prevProps.pipe !== this.props.pipe) {
      if (this.props.pipe) {
        this.getSourceSchema();
      }
    }

    if (
      this.props.pipe &&
      this.props.pipe['config_hash'] &&
      this.state.configHash !== this.props.pipe['config_hash']
    ) {
      // save the config hash to state, so we keep it if gets removed by the
      // load all pipes request
      this.setState({ configHash: this.props.pipe['config_hash'] });
    }

    if (!isEqual(prevProps.initialValue, this.props.initialValue)) {
      if (prevProps.isDirty && !this.state.saving) {
        const revert = confirm(
          'A new version of the pipe is available on the server. Revert your local changes?'
        );
        if (!revert) {
          return;
        }
      }

      this.setState({
        editorKey: this.state.editorKey + 1,
        value: this.props.initialValue,
      });
    }

    if (prevProps.pipe && this.props.pipe && prevProps.pipe._id !== this.props.pipe._id) {
      this.resetConfigHistory();
    }
  }

  componentDidMount() {
    this.getSourceSchema();
    this.getTargetSchemaIds();
    this.resetConfigHistory();

    if (
      this.props.pipe &&
      this.props.pipe['config_hash'] &&
      this.state.configHash !== this.props.pipe['config_hash']
    ) {
      // save the config hash to state, so we keep it if gets removed by the
      // load all pipes request
      this.setState({ configHash: this.props.pipe['config_hash'] });
    }
    this.unsubscribeFromRouter = (() => {
      let isDone = false;

      // .listenBefore has some unexpected behaviour where it will trigger multiple times on some routes.
      // 'isDone' is set to true when the user clicks 'ok' in the confirm and stops the function from triggering again.
      return this.props.router.listenBefore((location) => {
        if (!location.search) {
          if (!isDone) {
            if (this.props.isDirty || !this.state.validJson) {
              isDone = confirm(
                "You haven't saved yet, or your configuration contains errors. All changes will be lost. Are you sure you want to exit?"
              );
              if (isDone) {
                this.props.updateDirtyState(false);
              }
              return isDone;
            }
          }
        }
      });
    })();

    //TODO: This should be done betterly
    const strippedSearchQuery = location.search.replace('?', '');
    const queryString = qs.parse(strippedSearchQuery);
    if (
      queryString.jval ||
      queryString.sval ||
      (queryString.filter && queryString.filter !== DatasetInspectorFilters.LatestWithoutDeleted)
    ) {
      this.onPreview();
    }
  }

  componentWillUnmount() {
    this.unsubscribeFromRouter();
  }

  render() {
    const systems = this.props.systems;
    const sourceSystemIds = systems
      .filter((s) => s.runtime['has-source-configs'])
      .map((system) => system._id);
    const sinkSystemIds = systems
      .filter((s) => s.runtime['has-sink-configs'])
      .map((system) => system._id);
    const sourceTemplateTypes = this.state.sourceTemplates.map((source) => source.name).sort();
    const sinkTemplateTypes = this.state.sinkTemplates.map((source) => source.name);

    const sinkType = get(this.props.pipe, 'config.effective.sink.type');
    const effectiveConfig = get(this.props.pipe, 'config.effective');
    const subset = get(this.props.pipe, 'config.original.source.subset');

    const previewButton = (
      <Button
        active={this.state.showPreview}
        key="preview"
        disabled={!this.props.ready}
        onClick={this.togglePreview}
      >
        Preview (Ctrl+Enter)
      </Button>
    );

    const analyseButton = (
      <Button
        active={this.state.showAnalysis}
        key="analyze"
        disabled={!this.props.ready}
        onClick={this.onAnalyze}
      >
        Analyse
      </Button>
    );

    const generateSchemaButton =
      sinkType === 'dataset' ? (
        <Button
          active={this.state.showGeneratedSchemaPanel}
          key="generateSchema"
          onClick={this.onGenerateSchema}
          disabled={!this.state.showGeneratedSchemaPanel && this.props.isDirty}
        >
          Generate schema
        </Button>
      ) : null;

    const effectiveConfigButton = (
      <Button
        active={this.state.showEffectiveConfig}
        key="effectiveConfig"
        disabled={!this.props.ready}
        onClick={this.toggleEffectiveConfig}
      >
        Effective config
      </Button>
    );

    let targetSchemaProperties = [];
    let targetSchemaPanelButton = null;
    if (this.state.targetSchemaIds.length > 0) {
      targetSchemaProperties = propertiesToSchema(
        get(this.state.selectedTargetSchema, 'properties', {}),
        get(this.state.selectedTargetSchema, 'title', 'sink')
      );
    }
    targetSchemaPanelButton = (
      <Button
        active={this.state.showTargetSchema}
        key="targetSchema"
        disabled={!this.props.ready}
        onClick={() =>
          this.setState(({ showTargetSchema }) => ({
            showTargetSchema: !showTargetSchema,
          }))
        }
      >
        Target schema
      </Button>
    );

    return (
      <React.Fragment>
        {this.props.shouldShowToggle && this.props.showDataView && !this.props.isNewPipe ? (
          <DataView
            isDirty={this.props.isDirty}
            isValidConfig={this.state.validJson}
            message={this.props.message}
            onConfigChanged={this.onConfigChanged}
            onSave={this.onSave}
            pipe={this.props.pipe}
            pipeConfig={this.state.value}
            saving={this.state.saving}
            shortMessage={this.props.shortMessage}
            status={this.props.status}
          />
        ) : (
          <Editor
            extraToolbarPanelButtons={[
              previewButton,
              analyseButton,
              generateSchemaButton,
              effectiveConfigButton,
              targetSchemaPanelButton,
            ]}
            message={this.props.message}
            onSave={this.onSave}
            shortMessage={this.props.shortMessage}
            status={this.props.status}
            ready={this.props.ready}
          >
            <Replacements>
              <Replacement
                title="Config group"
                onReplaceTemplate={this.onReplaceConfigGroup}
                disabled={!this.state.selectedConfigGroup}
              >
                <ConfigGroupSelector
                  configGroups={this.props.configGroups}
                  onChangeConfigGroup={this.onConfigGroupChanged}
                  onSelectConfigGroup={this.onConfigGroupChanged}
                  selectedConfigGroup={this.state.selectedConfigGroup}
                />
              </Replacement>
              <Replacement
                title="Source"
                onReplaceTemplate={this.onReplaceSourceTemplate}
                disabled={!this.state.selectedSourceTemplate}
              >
                <SesamTextField
                  id="sourceSystem"
                  label="System"
                  margin="normal"
                  onChange={(ev) => this.onSourceSystemSelected(ev.target.value)}
                  value={this.state.selectedSourceSystem || ''}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => value || '-',
                  }}
                  select
                >
                  {sourceSystemIds.map((id) => (
                    <SesamMenuItem key={id} value={id}>
                      {id}
                    </SesamMenuItem>
                  ))}
                </SesamTextField>

                <SesamTextField
                  id="sourceTemplate"
                  label="Provider"
                  margin="normal"
                  onChange={(ev) => this.onSourceTemplateChanged(ev.target.value)}
                  value={this.state.selectedSourceTemplate || ''}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => value || '-',
                  }}
                  select
                >
                  {sourceTemplateTypes.map((id) => (
                    <SesamMenuItem key={id} value={id}>
                      {id}
                    </SesamMenuItem>
                  ))}
                </SesamTextField>
              </Replacement>
              <Replacement
                title="Transforms"
                buttonLabel="Add DTL transform"
                onReplaceTemplate={this.onAddDtlTransform}
              />
              <Replacement
                title="Target"
                onReplaceTemplate={this.onReplaceSinkTemplate}
                disabled={!this.state.selectedSinkTemplate}
              >
                <SesamTextField
                  id="sinkSystem"
                  label="System"
                  margin="normal"
                  onChange={(ev) => this.onSinkSystemSelected(ev.target.value)}
                  value={this.state.selectedSinkSystem || ''}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => value || '-',
                  }}
                  select
                >
                  {sinkSystemIds.map((id) => (
                    <SesamMenuItem key={id} value={id}>
                      {id}
                    </SesamMenuItem>
                  ))}
                </SesamTextField>

                <SesamTextField
                  id="sinkTemplate"
                  label="Sink"
                  margin="normal"
                  onChange={(ev) => this.onSinkTemplateChanged(ev.target.value)}
                  value={this.state.selectedSinkTemplate || ''}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => value || '-',
                  }}
                  select
                >
                  {sinkTemplateTypes.map((id) => (
                    <SesamMenuItem key={id} value={id}>
                      {id}
                    </SesamMenuItem>
                  ))}
                </SesamTextField>
              </Replacement>
              {(sinkType === 'sql' || sinkType === 'csv_endpoint') && (
                <Replacement
                  title="Target schema"
                  buttonLabel="Replace"
                  onReplaceTemplate={this.onReplaceSinkSchema}
                  actions={
                    <LinkButton onClick={() => this.setState({ showSchemaDialog: true })}>
                      Larger samples…
                    </LinkButton>
                  }
                >
                  <label htmlFor="sinkSchemaSampleSize">Sample size</label>
                  <SesamSlider
                    defaultValue={500}
                    marks
                    max={1000}
                    min={10}
                    onChange={(ev, val) => this.handleSampleSizeChange(val)}
                    step={100}
                    value={Number(this.state.sinkSchemaSampleSize)}
                    valueLabelDisplay="auto"
                  />

                  <SesamModal
                    isOpen={this.state.showSchemaDialog}
                    onRequestClose={this.closeSchemaDialog}
                    contentLabel="Generate schema"
                    shouldCloseOnOverlayClick={false}
                    style={{
                      content: {
                        bottom: 'auto',
                        left: '50%',
                        marginRight: '-50%',
                        right: 'auto',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                      },
                    }}
                  >
                    <div className="pipe-editor__modal">
                      {(!this.state.generatedSinkSchema || this.state.fetchingGeneratedSchema) && (
                        <Form component="div">
                          <h3 className="heading-section">Generate schema</h3>
                          <SesamTextField
                            label="Sample size"
                            id="sinkSchemaFreeSampleSize"
                            min="1"
                            onChange={this.handleFieldChange}
                            type="number"
                            value={this.state.sinkSchemaFreeSampleSize}
                            helperText="Please note that large samples can take a long
                          time to return a result."
                          />
                          <FormActions>
                            <Button
                              disabled={this.state.fetchingGeneratedSchema}
                              onClick={this.closeSchemaDialog}
                            >
                              Cancel
                            </Button>
                            <Button
                              disabled={this.state.fetchingGeneratedSchema}
                              onClick={this.fetchSinkSchemaFreeSampleSize}
                            >
                              Generate
                            </Button>
                          </FormActions>
                        </Form>
                      )}
                      {this.state.fetchingGeneratedSchema && <LoadingPanel />}
                      {this.state.generatedSinkSchema && (
                        <Form component="div">
                          <h3 className="heading-component">Generated schema</h3>
                          <JsonPanel
                            alwaysUpdate
                            filter={false}
                            rawJson={this.state.generatedSinkSchema}
                          />
                          <FormActions>
                            <Button onClick={() => this.setState({ generatedSinkSchema: null })}>
                              Back
                            </Button>
                            <Button onClick={this.closeSchemaDialog}>
                              Close without replacing
                            </Button>
                            <Button onClick={this.replaceSinkSchema}>Replace</Button>
                          </FormActions>
                        </Form>
                      )}
                    </div>
                  </SesamModal>
                </Replacement>
              )}
              <Replacement
                title="Schedule"
                onReplaceTemplate={this.onReplaceSchedule}
                disabled={!this.state.schedule}
              >
                <SesamTextField
                  margin="normal"
                  onChange={(ev) => this.onScheduleChanged(ev.target.value)}
                  value={this.state.schedule || ''}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => value || '-',
                  }}
                  select
                >
                  <SesamMenuItem value="custom">Customized</SesamMenuItem>
                  <SesamMenuItem value="hourly">Hourly</SesamMenuItem>
                  <SesamMenuItem value="daily">Daily</SesamMenuItem>
                  <SesamMenuItem value="weekly">Weekly</SesamMenuItem>
                  <SesamMenuItem value="monthly">Monthly</SesamMenuItem>
                  <SesamMenuItem value="yearly">Yearly</SesamMenuItem>
                </SesamTextField>
              </Replacement>
            </Replacements>
            <Configuration
              value={this.state.value}
              onChange={this.onConfigChanged}
              key={this.state.editorKey}
              onRun={this.onPreview}
              onSave={this.onSave}
              onValidateJson={(validJson) => this.setState({ validJson })}
              isNewPipe={this.props.isNewPipe}
              targetSchema={targetSchemaProperties}
              sourceSchema={this.state.sourceSchema}
              registerRefresh={this.props.registerRefresh}
              unregisterRefresh={this.props.unregisterRefresh}
              configHash={this.state.configHash}
              compare={this.state.showComparePanel}
              compareValue={this.state.compareCurrent}
              currentConfigEntity={this.state.currentConfigEntity}
              saved={this.state.saved}
              pipe={this.props.pipe}
            >
              {this.props.children}
            </Configuration>
            <PipePreviewPanel
              show={this.state.showPreview}
              outputReady={this.state.outputReady}
              outputInProgress={this.state.outputInProgress}
              outputStale={this.state.outputStale}
              startingRun={this.state.startingRun}
              output={this.state.output}
              transformed={this.state.transformed}
              pipe={this.state.value}
              pipeObjectConfig={get(this.props.pipe, 'config')}
              pipeId={get(this.props.pipe, '_id')}
              subset={subset}
              sourceData={this.state.sourceData}
              sampleData={this.state.sampleData}
              revertCount={this.state.revertCount}
              previewErrors={this.state.previewErrors}
              onHandleEntityFetched={this.onHandleEntityFetched}
              onRun={this.onPreview}
              onSourceChanged={this.onSourceChanged}
              onRevertSource={this.onRevertSource}
              onDebug={this.toggleDebug}
            />
            <PipeAnalysePanel
              show={this.state.showAnalysis}
              analysisReady={this.state.analysisReady}
              analysis={this.state.analysis}
              pipe={this.state.value}
            />
            {this.state.showGeneratedSchemaPanel && (
              <GenerateSchemaPanelContainer pipeId={this.state.value._id} />
            )}
            {this.state.showEffectiveConfig && <JsonPreviewPanel json={effectiveConfig} />}
            {this.state.showDebugger && (
              <DTLDebuggerPanel
                trace={this.previewTraceInfo.trace}
                onChangeStep={this.highlightDTLExpression}
                onExit={this.toggleDebug}
                id={this.previewTraceInfo.id}
              />
            )}
            {this.state.showTargetSchema && (
              <TargetSchemaPanel
                targetSchemaIds={this.state.targetSchemaIds}
                selectedSchema={this.state.selectedTargetSchema}
                onSelectSchema={this.onSelectedTargetSchemaChanged}
              />
            )}
          </Editor>
        )}
        <div className="pipe-editor__action-bar">
          {!this.props.showDataView && (
            <React.Fragment>
              <span style={{ fontSize: '0.75rem', margin: '1rem 0.5rem 0 0' }}>
                Config history:{' '}
              </span>
              <Button
                onClick={this.navigatePrevious}
                disabled={
                  (!this.state.comparePrevious && this.state.saved) ||
                  (this.state.showComparePanel && !this.state.comparePrevious)
                }
              >
                Previous
              </Button>
              <Button
                onClick={this.navigateNext}
                disabled={
                  !this.state.showComparePanel || (!this.state.compareNext && this.state.saved)
                }
              >
                Next
              </Button>
              <Button onClick={this.resetConfigHistory} disabled={!this.state.showComparePanel}>
                Back to latest
              </Button>
            </React.Fragment>
          )}

          <ActionBar
            message={this.props.message}
            shortMessage={this.props.shortMessage}
            status={this.props.status}
          >
            <ExternalLink
              href={Links.QuickReferenceDocumentation}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 'auto' }}
            >
              DTL Reference
            </ExternalLink>
            {this.props.shouldShowToggle && (
              <SesamRadioGroupField
                FormControlProps={{
                  style: { marginRight: '11px' },
                }}
                value={this.props.showDataView}
                onChange={this.props.onToggleView}
                radios={[
                  { label: 'Code view', value: false },
                  { label: 'Data view', value: true, noMarginRight: true },
                ]}
                row
              />
            )}
            <Button
              onClick={this.onSave}
              theme="primary"
              style={{ marginLeft: '0.5rem' }}
              disabled={
                !this.state.validJson || (!this.props.isDirty && !this.props.isDuplicatedPipe)
              }
            >
              Save
            </Button>
          </ActionBar>
        </div>
      </React.Fragment>
    );
  }
}

PipeEditor.propTypes = {
  analysePipe: PropTypes.func.isRequired,
  getSinkPrototypes: PropTypes.func.isRequired,
  getSourcePrototypes: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  initialDirty: PropTypes.bool,
  initialValue: PropTypes.any.isRequired,
  initialSaved: PropTypes.bool,
  layoutChange: PropTypes.func.isRequired,
  message: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  previewPipe: PropTypes.func.isRequired,
  shortMessage: PropTypes.string,
  showDataView: PropTypes.bool,
  status: PropTypes.string,
  systems: PropTypes.array.isRequired,
  onAfterSave: PropTypes.func,
  ready: PropTypes.bool,
  isDuplicatedPipe: PropTypes.bool,
  isNewPipe: PropTypes.bool,
  shouldShowToggle: PropTypes.bool,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  pipe: PropTypes.object,
  pipes: PropTypes.shape({}).isRequired,
  upstreams: PropTypes.shape({}).isRequired,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
  updateDirtyState: PropTypes.func.isRequired,
  children: PropTypes.node,
  onToggleView: PropTypes.func,
  configGroups: PropTypes.arrayOf(PropTypes.string),
};

PipeEditor.defaultProps = {
  onAfterSave: () => null,
};

function mapStateToProps(state) {
  return {
    isDirty: state.editor.isDirty,
    subUrl: state.subscription.url,
    token: state.subscription.token,
    pipes: state.pipes,
    upstreams: state.upstreams,
    configGroups: configGroupsSelector(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  analysePipe: (pipe) => dispatch(PipeActions.analysePipe(pipe)),
  apologise: (msg) => dispatch(apologise(msg)),
  previewPipe: (pipe, entity, useTrace) =>
    dispatch(PipeActions.previewPipe(pipe, entity, useTrace)),
  layoutChange: () => dispatch(layoutChanged()),
  getSinkPrototypes: (systemId) => dispatch(SystemActions.getSinkPrototypes(systemId)),
  getSourcePrototypes: (systemId) => dispatch(SystemActions.getSourcePrototypes(systemId)),
  updateDirtyState: (isDirty) => dispatch(EditorActions.dirtyStateUpdated(isDirty)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PipeEditor));
