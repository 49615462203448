import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Recaptcha from 'react-google-recaptcha';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import { push } from 'react-router-redux';
import yup from 'yup';
import Button from 'Common/Button/Button';
import { Form } from 'Common/forms';
import Feedback from '../../../components/feedback';
import Actions from 'Redux/thunks/auth';

import PasswordMeterTextField from 'Common/PasswordMeterTextField/PasswordMeterTextField';
import SesamCheckboxField from 'Common/SesamCheckboxField/SesamCheckboxField';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import ExternalLink from 'Common/Links/ExternalLink';
import { Links } from 'Constants/links';
import './style.css';

const minPasswordLength = 12;

const InnerForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  isValid,
}) => {
  const isError = (name) => touched[name] && Boolean(errors[name]);
  const getHelperText = (name) => touched[name] && errors[name];

  return (
    <div className="material-form">
      <Form onSubmit={handleSubmit}>
        <SesamTextField
          inputProps={{ autoFocus: true }}
          margin="normal"
          id="name"
          type="text"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={isError('name')}
          helperText={getHelperText('name')}
          label="Name"
        />
        <SesamTextField
          margin="normal"
          id="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={isError('email')}
          helperText={getHelperText('email')}
          label="Email"
        />
        <PasswordMeterTextField
          margin="normal"
          id="password"
          value={values.password}
          onChange={handleChange}
          label="Password"
          customDict={[values.name, values.email]}
          minLength={minPasswordLength}
        />
        <SesamTextField
          margin="normal"
          id="retypePassword"
          type="password"
          value={values.retypePassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={isError('retypePassword')}
          label="Retype password"
          helperText={getHelperText('retypePassword')}
        />
        <SesamCheckboxField
          CheckboxProps={{
            id: 'acceptedTerms',
            checked: values.acceptedTerms,
            onChange: handleChange,
            onBlur: handleBlur,
            'data-selenium': 'acceptedTerms',
          }}
          formControlProps={{
            margin: 'normal',
            error: isError('acceptedTerms'),
          }}
          helperText={errors.acceptedTerms}
          label={
            <span>
              I agree to the{' '}
              <ExternalLink
                target="_blank"
                rel="noopener noreferrer"
                href={Links.TermsOfService}
              >
                Terms of Service
              </ExternalLink>{' '}
              and{' '}
              <ExternalLink
                target="_blank"
                rel="noopener noreferrer"
                href={Links.PrivacyPolicy}
              >
                Privacy Policy
              </ExternalLink>
            </span>
          }
        />
        {values.isItestMode && (
          <div>
            <div>NOTE: Recaptcha has been disabled, since the 'isItestMode' flag is set.</div>
          </div>
        )}
        {!values.isItestMode && (
          <div style={{ marginBottom: '15px' }}>
            <label className="material-form__label" htmlFor="captchaResponse">
              Confirm you&apos;re not a robot
            </label>
            <Recaptcha
              className="material-form__captcha"
              id="captchaResponse"
              onChange={(value) => setFieldValue('captchaResponse', value)}
              sitekey="6Lcsr8IZAAAAAJQoK5c_BTn0VTf10d4ur6LLuD7g"
            />
            {touched.captchaResponse && errors.captchaResponse && (
              <span className="material-form__error">{errors.captchaResponse}</span>
            )}
          </div>
        )}
        {values.signupFailed && <Feedback type="warning">Email is already in use</Feedback>}
        <Button disabled={isSubmitting || !isValid} type="submit">
          {isSubmitting ? 'Signing up...' : 'Next'}
        </Button>
        <span className="signup-form-new__log-in-link">
          Already have an account? <SesamLink to="/auth/login">Log in instead</SesamLink>
        </span>
      </Form>
    </div>
  );
};

InnerForm.propTypes = {
  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

const SignupForm = withFormik({
  displayName: 'Sesam Signup Form',
  mapPropsToValues: (props) => ({
    name: '',
    email: '',
    password: '',
    retypePassword: '',
    acceptedTerms: false,
    captchaResponse: props.isItestMode ? 'test' : '',
    isItestMode: props.isItestMode,
    signupFailed: props.signupFailed,
  }),
  validationSchema: yup.object().shape({
    name: yup.string().required('Please type in your name'),
    email: yup.string().email('Must be a valid email').required('Please type in your email'),
    password: yup.string().min(minPasswordLength).required('Please choose a password'),
    retypePassword: yup
      .string()
      .oneOf([yup.ref('password')], "Passwords don't match")
      .required('Please retype your password'),
    acceptedTerms: yup.boolean().oneOf([true], 'You must accept our terms'),
    captchaResponse: yup.string().nullable().required('Please solve the CAPTCHA'),
  }),
  handleSubmit: (values, { props, resetForm }) => {
    const location = props.location;
    const redirect = location.query.redirect;
    const data = {
      name: values.name,
      email: values.email,
      password: values.password,
      captchaResponse: values.captchaResponse,
    };
    props
      .signup(data)
      .then(() => {
        if (redirect && !redirect.startsWith('/auth/signup')) {
          props.redirect({
            pathname: redirect,
            query: (location.state && location.state.query) || null,
          });
        } else {
          props.welcome();
        }
      })
      .catch(() => {
        resetForm();
      });
  },
})(InnerForm);

SignupForm.PropTypes = {
  signup: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  welcome: PropTypes.func.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      redirect: PropTypes.string,
    }).isRequired,
  }).isRequired,
  signupFailed: PropTypes.bool,
  isItestMode: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    signupFailed: state.user.signupFailed,
    isItestMode: state.globals.status.is_itest_mode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signup: (data) => dispatch(Actions.attemptSignup(data)),
    redirect: (location) => dispatch(push(location)),
    welcome: () => dispatch(push('/')),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
