import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useEffect, useState } from 'react';

import { LoadingPanel } from 'Common/LoadingPanel';
import EmptyState from 'Common/EmptyState/EmptyState';
import LineageAPI from 'Api/lineage';
import PipeIcon from '../../images/icons/pipe.svg';

import KeyValue from '../key-value';
import './EntityTypePropertyOverviewStyle.css';
import dagre from 'dagre';
import ReactFlow, {
  MiniMap,
  Controls,
  MarkerType,
  applyEdgeChanges,
  applyNodeChanges,
} from 'react-flow-renderer';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import Divider from '@material-ui/core/Divider';
import { Tooltip } from '@material-ui/core';
import SesamLink from 'Common/Links/SesamLink';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { isProvisionerVersion2 } from 'Redux/selectors';
import MetadataAPI from '../../api/metadata';
import ExternalLink from 'Common/Links/ExternalLink';
import { Links } from 'Constants/links';

const useStyles = makeStyles((theme) => ({
  linkColor: {
    color: theme.palette.primary.main,
    '&:hover, &:active': {
      cursor: 'pointer',
    },
  },
}));

function translatePropertyGraph(propertyGraph, property, pipes, subId, darkModeActive) {
  let nodes = propertyGraph.nodes;
  let edges = propertyGraph.edges;
  // map the nodes to objects for the library
  nodes = nodes.map((node) => {
    let isOriginatingPipe = false;
    const nodePipe = pipes[node.pipe_id];
    const pipeNamespace = get(nodePipe, 'config.effective.namespaces.property', node.pipe_id);
    const propertyParts = property.split(':');
    if (propertyParts.length > 1) {
      const propertyNamespace = propertyParts[0];
      if (pipeNamespace === propertyNamespace) {
        isOriginatingPipe = true;
      }
    }

    let tooltip = '';
    let borderWidth = 1;
    let borderColor = darkModeActive ? 'grey' : 'black';
    let backgroundColor = darkModeActive ? '#22272e' : '#fff';
    if (isOriginatingPipe) {
      tooltip = `Pipe ${node.pipe_id} (origin)`;
      borderWidth = 3;
      borderColor = '#0081c1';
    } else {
      tooltip = `Pipe ${node.pipe_id}`;
    }

    return {
      id: node.pipe_id,
      style: {
        borderColor: borderColor,
        width: 400,
        borderWidth: borderWidth,
        backgroundColor,
        textAlign: 'left',
        borderRadius: 0.5,
      },
      data: {
        properties: node.properties,
        label: (
          <>
            <Tooltip title={tooltip}>
              <Typography variant="h5">
                <SesamLink to={`/subscription/${subId}/pipes/pipe/${node.pipe_id}`}>
                  <PipeIcon
                    width="32"
                    height="32"
                    style={{ marginRight: '0.5em', verticalAlign: 'middle' }}
                  />
                  {node.pipe_id}
                </SesamLink>
              </Typography>
            </Tooltip>
            <Divider />
            {node.properties.length > 10
              ? 'More than 10 properties'
              : node.properties.map((p) => {
                  let shouldBeLink = true;
                  if (p === property) shouldBeLink = false;
                  const parts = p.split(':');
                  if (parts.length === 1) shouldBeLink = false;
                  if ((parts.length > 0 && parts[0] === 'rdf') || parts[0] === 'rdfs')
                    shouldBeLink = false;

                  return (
                    <>
                      <Tooltip title={`Property ${p}}`}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          {!shouldBeLink && (
                            <span style={{ color: `${darkModeActive ? '#d0d0d0' : '#000'}` }}>
                              {p}
                            </span>
                          )}
                          {shouldBeLink && (
                            <SesamLink to={`/subscription/${subId}/browse/properties/${p}`}>
                              {p}
                            </SesamLink>
                          )}
                        </Typography>
                      </Tooltip>
                    </>
                  );
                })}
          </>
        ),
      },
    };
  });

  function update(obj, key, value) {
    if (obj[key]) {
      obj[key].push(value);
    } else {
      obj[key] = [value];
    }
  }

  const start2end = {};
  const end2start = {};
  // map the edges to objects for the library, also save the mappings above
  edges = edges.map((edge) => {
    update(start2end, edge.start, edge.end);
    update(end2start, edge.end, edge.start);
    return {
      id: `e${edge.start}-${edge.end}`,
      source: edge.start,
      target: edge.end,
      style: {
        stroke: '#0081c1',
      },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: '#0081c1',
      },
    };
  });

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 400;
  const nodeHeight = 100;

  const getLayoutedElements = (nodes, edges, direction = 'TB') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction, ranker: 'tight-tree', ranksep: 100 });

    nodes.forEach((node) => {
      const propLen = node.data.properties.length > 10 ? 10 : node.data.properties.length;
      let modifiedNodeHeight = nodeHeight + (propLen - 1) * 40;
      dagreGraph.setNode(node.id, { width: nodeWidth, height: modifiedNodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? 'left' : 'top';
      node.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      if (start2end[node.id] && !end2start[node.id]) {
        node.type = 'input';
      } else if (!start2end[node.id] && end2start[node.id]) {
        node.type = 'output';
      }

      return node;
    });

    return { nodes, edges };
  };

  return getLayoutedElements(nodes, edges, 'LR');
}

const EntityTypePropertyOverview = (props) => {
  const {
    subUrl,
    token,
    subId,
    pipes,
    params: { propertyId },
    darkModeActive,
    search,
    serviceType,
    isProvisionerVersion2,
  } = props;

  const styles = useStyles();
  const [lineageEnabled, setLineageEnabled] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nodes, setNodes] = useState();
  const [edges, setEdges] = useState();

  // Check if lineage service works
  useEffect(() => {
    async function fetchLineageRoot(apiConf, propertyId) {
      let response = await LineageAPI.getRoot(apiConf);
      setLineageEnabled(true);
    }
    fetchLineageRoot({ subUrl, token }).catch((e) => {
      setLineageEnabled(false);
      setLoading(false);
    });
  }, [subUrl, token]);

  // Get the property graph
  useEffect(() => {
    async function fetchPropertyGraph(apiConf, propertyId) {
      let propertyGraph = await LineageAPI.getPropertyGraph(apiConf, propertyId);
      propertyGraph = translatePropertyGraph(
        propertyGraph,
        propertyId,
        pipes,
        subId,
        darkModeActive
      );
      setNodes(propertyGraph.nodes);
      setEdges(propertyGraph.edges);
      setLoading(false);
    }
    if (lineageEnabled) {
      fetchPropertyGraph({ subUrl, token }, propertyId).catch((e) => {
        setError(e);
        setLoading(false);
      });
    }
  }, [subUrl, token, propertyId, darkModeActive, lineageEnabled]);

  const [metadata, setMetadata] = useState(null);
  useEffect(() => {
    MetadataAPI.get({ subUrl, token })
      .then((jsonData) => {
        if (jsonData.config.original.$audit) {
          delete jsonData.config.original.$audit;
        }
        setMetadata(jsonData.config.original);
      })
      .catch((error) => {
        setMetadata(null);
        console.log('metadata request failed', error);
      });
  }, [subUrl, token]);

  const isSchemaCollectionOn = (metadata) => {
    return get(metadata, 'global_defaults.infer_pipe_entity_types', true);
  };

  const schemaCollection = useMemo(() => {
    if (metadata) {
      return isSchemaCollectionOn(metadata);
    }
  }, [metadata]);

  if (!propertyId) {
    return <div>No entity type specified.</div>;
  }

  const onNodesChange = useCallback(
    (changes) => {
      let proceed = true;
      for (const change in changes) if (changes[change].type === 'remove') proceed = false;
      if (proceed) setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      let proceed = true;
      for (const change in changes) if (changes[change].type === 'remove') proceed = false;
      if (proceed) setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  let propertyLineage = null;
  if (loading) {
    propertyLineage = <LoadingPanel loadingMessage={'Loading property lineage...'} />;
  } else if (
    (!lineageEnabled || schemaCollection === false) &&
    serviceType === 'in-cloud' &&
    isProvisionerVersion2
  ) {
    propertyLineage = (
      <EmptyState>
        {!lineageEnabled && (
          <>
            Property lineage tracking is disabled for this subscription.{' '}
            <a
              className={styles.linkColor}
              onClick={() => {
                props.router.push(`/subscription/${subId}/settings-subscription/products`);
              }}
            >
              Enable Integrated search and property lineage
            </a>
            .
          </>
        )}
        {schemaCollection === false && (
          <div style={{ margin: '10px 0 10px 1px' }}>
            You need to enable schema inferencing in order to use property lineage. Enable{' '}
            <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              href={Links.SchemaInferencingDocumentation}
            >
              user schema inferencing
            </ExternalLink>{' '}
            metadata{' '}
            <SesamLink to={`/subscription/${props.subId}/settings-datahub/metadata`}>
              here
            </SesamLink>
            {'.'}
          </div>
        )}
      </EmptyState>
    );
  } else if (error) {
    propertyLineage = (
      <EmptyState>{`No lineage data found for property "${propertyId}".`}</EmptyState>
    );
  } else {
    propertyLineage = (
      <div className="entity-type-overview__flow">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          fitView={true}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          className={darkModeActive ? 'darkMode' : 'lightMode'}
        >
          <MiniMap
            style={{ backgroundColor: `${darkModeActive ? '#22272e' : '#fff'}` }}
            className={darkModeActive ? 'darkMode' : 'lightMode'}
          />
          <Controls />
        </ReactFlow>
      </div>
    );
  }

  return (
    <div className="entity-type-overview">
      <div className="entity-type-overview__key-value">
        <KeyValue list={{ Property: propertyId }} />
      </div>
      {propertyLineage}
    </div>
  );
};

EntityTypePropertyOverview.propTypes = {
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  subId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    entityTypeId: PropTypes.string.isRequired,
    modelId: PropTypes.string.isRequired,
    propertyId: PropTypes.string.isRequired,
  }).isRequired,
  darkModeActive: PropTypes.bool.isRequired,
  search: PropTypes.bool.isRequired,
  serviceType: PropTypes.string.isRequired,
  isProvisionerVersion2: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const subData = state.subscriptions.find((s) => s.id === state.subscription.id);
  return {
    subUrl: state.subscription.url,
    token: state.subscription.token,
    subId: state.subscription.id,
    pipes: state.pipes,
    darkModeActive: state.theme.dark,
    serviceType: get(subData, 'service'),
    isProvisionerVersion2: isProvisionerVersion2(state),
  };
}

export default compose(connect(mapStateToProps), withRouter)(EntityTypePropertyOverview);
