import debounce from 'lodash/debounce';
import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { ChromePicker } from 'react-color';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import ExternalLink from 'Common/Links/ExternalLink';
import sfetch from 'Internals/sfetch';
import SubActions from 'Redux/thunks/subscriptions';
import EnvVarThunks from 'Redux/thunks/vars';

import ActionBar from '../../../components/action-bar';
import InProgress from '../../../components/in-progress';
import PromiseButton from '../../../components/promise-button';
import { getGdprMsUrl } from '../gdpr/util';

import './style.css';
import { Links } from 'Constants/links';

class GdprDataTypes extends React.Component {
  constructor(props) {
    super();

    this.state = {
      message: '',
      databrowserLogoUploadCount: 0,
      'phone-prefix': props['phone-prefix'],
      'default-lang': props['default-lang'],
      'setup-file-url': props['setup-file-url'],
      domainAvailable: false,
      checkingDomain: false,
      domain: props.domain,
      logoPath: '',
      colors: {
        message: '',
        primary: {
          code: '#ffffff',
        },
        secondary: {
          code: '#ffffff',
        },
      },
    };

    this.upload = (e) => {
      e.preventDefault();

      const file = this.state.file;
      if (!file) {
        this.setState({ message: 'You must select a file' });
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      const options = {
        body: formData,
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'PUT',
      };

      sfetch(`${this.props.gdprMicroserviceURL}/parse-template`, options)
        .then(() => {
          this.setState({ message: 'File uploaded successfully.' });
        })
        .catch(() => {
          this.setState({ message: 'Something went wrong. Please try again.' });
        });
    };

    this.handleFileChange = (ev) => {
      this.setState({
        file: ev.target.files[0],
      });
    };

    this.handleDatabrowserLogoFileChange = (ev) => {
      this.setState({
        databrowserLogoFile: ev.target.files[0],
      });
    };

    this.uploadDatabrowserLogo = async (e) => {
      e.preventDefault();

      const file = this.state.databrowserLogoFile;
      if (!file) {
        this.setState({ databrowserLogoMessage: 'You must select a file' });
        return;
      }

      this.setState({
        uploadDatabrowserLogoInProgress: true,
      });

      const timestamp = Date.now();
      const logoExtension = file.name.split('.').pop();
      const logoFileName = `customlogo_${timestamp}.${logoExtension}`;
      const logoCssFileName = `customlogo_${timestamp}.css`;

      /* Delete static folder */
      const deleteOptions = {
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'DELETE',
      };

      await sfetch(`${this.props.databrowserURL}/configuration/conf/20000/static`, deleteOptions);

      /* Add logo_info.json */
      const logoInfo = {
        content_type: file.type,
        filename: logoFileName,
      };

      const logoInfoBlob = new Blob([JSON.stringify(logoInfo)], {
        type: 'application/json',
      });

      const logoInfoFormData = new FormData();
      logoInfoFormData.append('file', logoInfoBlob);

      const logoInfoOptions = {
        body: logoInfoFormData,
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'PUT',
      };

      await sfetch(
        `${this.props.databrowserURL}/configuration/conf/20000/logo_info.json`,
        logoInfoOptions
      );

      /* Add databrowser.ini file */
      const configText = `[main]\n\nstatic_asset_folders=\n    customlogostatic:/sesam/data/conf/20000/static\n\ninstallation_specific_css_files=\n    ./customlogostatic/${logoCssFileName}`;

      const configBlob = new Blob([configText], { type: 'text/plain' });

      const configFormData = new FormData();
      configFormData.append('file', configBlob);

      const configOptions = {
        body: configFormData,
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'PUT',
      };

      await sfetch(
        `${this.props.databrowserURL}/configuration/conf/20000/databrowser.ini`,
        configOptions
      );

      /* Add CSS-file */
      const cssText = `header .logotype { background-image: url('./${logoFileName}'); }`;

      const cssBlob = new Blob([cssText], { type: 'text/css' });

      const cssFormData = new FormData();
      cssFormData.append('file', cssBlob);

      const cssOptions = {
        body: cssFormData,
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'PUT',
      };

      await sfetch(
        `${this.props.databrowserURL}/configuration/conf/20000/static/${logoCssFileName}`,
        cssOptions
      );

      /* Add logo */
      const formData = new FormData();
      formData.append('file', file);

      const options = {
        body: formData,
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'PUT',
      };

      await sfetch(
        `${this.props.databrowserURL}/configuration/conf/20000/static/${logoFileName}`,
        options
      );

      this.setState({
        databrowserLogoMessage: 'File uploaded successfully.',
        uploadDatabrowserLogoInProgress: false,
        databrowserLogoUploadCount: this.state.databrowserLogoUploadCount + 1,
        logoPath: `${this.props.databrowserURL}/customlogostatic/${logoFileName}`,
      });
    };

    this.handleChange = (ev) => {
      this.setState({
        [ev.target.id]: ev.target.value,
      });
    };

    this.setLanguage = async (ev) => {
      ev.preventDefault();

      const defaultLanguage = this.state['default-lang'];

      await this.props.setLanguageEnvironmentVariable(defaultLanguage);

      /* Upload a new "/configuration/conf/102/databrowser.ini" file. NOTE: this file is overwritten by the
       * gdpr-ms microservice when the microservice starts up, so it is important that it is never used for
       * anything other than the default_language setting. (The microservice gets the default langauge
       * from the node's environment variables, so it will write the correct language to the databrowser.ini file.
       * The reason we write the databrowser.ini file here is that we want the change to take effect in the
       * databrowser immediately.
       */
      const configText = `[i18n]\ndefault_language=${defaultLanguage}\n`;

      const configBlob = new Blob([configText], { type: 'text/plain' });

      const configFormData = new FormData();
      configFormData.append('file', configBlob);

      const configOptions = {
        body: configFormData,
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'PUT',
      };

      await sfetch(
        `${this.props.databrowserURL}/configuration/conf/102/databrowser.ini`,
        configOptions
      );
    };

    this.setPhonePrefix = (ev) => {
      ev.preventDefault();
      return this.props.setPhonePrefix(this.state['phone-prefix']);
    };

    this.setSetupFileUrl = (ev) => {
      ev.preventDefault();
      return this.props.setSetupFileUrl(this.state['setup-file-url']);
    };

    this.removeSetupFileUrl = (ev) => {
      ev.preventDefault();
      return this.props.removeSetupFileUrl();
    };

    this.checkDomainDebounced = debounce(
      (domain) => {
        this.props.checkDomain(domain).then((result) => {
          this.setState({
            resolvedDomain: result.resolvedDomain,
            domainAvailable: result.available,
            checkingDomain: false,
          });
        });
      },
      2000,
      { leading: false, trailing: true }
    );

    this.handleDomainChange = (ev) => {
      ev.preventDefault();
      const domain = ev.target.value;
      this.setState({
        domain,
        checkingDomain: true,
        domainAvailable: false,
        resolvedDomain: null,
      });
      this.checkDomainDebounced(domain);
    };

    this.updateDomain = (ev) => {
      ev.preventDefault();
      return this.props.updateDomain(this.state.resolvedDomain).then(() => {
        this.setState({
          resolvedDomain: null,
          domainAvailable: false,
        });
      });
    };

    this.setLogoUrl = async () => {
      /* Get logo_info.json */
      const options = {
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'GET',
      };

      const stream = await sfetch(
        `${this.props.databrowserURL}/configuration/conf/20000/logo_info.json`,
        options
      );

      let file;
      try {
        file = await stream.json();
      } catch (e) {
        return;
      }

      this.setState({
        logoPath: `${this.props.databrowserURL}/customlogostatic/${file.filename}`,
      });
    };

    this.handleColorChange = (color, name) => {
      const colorsObj = Object.assign({}, this.state.colors);

      colorsObj[name].code = color.hex;

      this.setState({
        colors: colorsObj,
      });
    };

    this.uploadColorInfo = async (e) => {
      e.preventDefault();

      /* Add color_info.json */
      const colorObj = Object.assign({}, this.state.colors);
      const colorInfo = {
        primary: this.state.colors.primary.code,
        secondary: this.state.colors.secondary.code,
      };

      const colorInfoBlob = new Blob([JSON.stringify(colorInfo)], {
        type: 'application/json',
      });

      const colorInfoFormData = new FormData();
      colorInfoFormData.append('file', colorInfoBlob);

      const colorInfoOptions = {
        body: colorInfoFormData,
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'PUT',
      };

      await sfetch(
        `${this.props.databrowserURL}/configuration/conf/20001/color_info.json`,
        colorInfoOptions
      );

      /* Add databrowser.ini file */
      const configText =
        '[main]\n\nstatic_asset_folders=\n    customcolorsstatic:/sesam/data/conf/20001/static\n\ninstallation_specific_css_files=\n    ./customcolorsstatic/custom_colors.css';

      const configBlob = new Blob([configText], { type: 'text/plain' });

      const configFormData = new FormData();
      configFormData.append('file', configBlob);

      const configOptions = {
        body: configFormData,
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'PUT',
      };

      await sfetch(
        `${this.props.databrowserURL}/configuration/conf/20001/databrowser.ini`,
        configOptions
      );

      /* Add custom_colors.css */
      const cssText = `.gdpr header { background-color: ${this.state.colors.primary.code}; }`;

      const cssBlob = new Blob([cssText], { type: 'text/css' });

      const cssFormData = new FormData();
      cssFormData.append('file', cssBlob);

      const cssOptions = {
        body: cssFormData,
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'PUT',
      };

      await sfetch(
        `${this.props.databrowserURL}/configuration/conf/20001/static/custom_colors.css`,
        cssOptions
      );

      colorObj.message = 'The colors has been successfully updated';

      this.setState({
        colors: colorObj,
      });
    };

    this.setColors = async () => {
      /* Get colors_info.json */
      const colorObj = Object.assign({}, this.state.colors);
      const options = {
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'GET',
      };

      const stream = await sfetch(
        `${this.props.databrowserURL}/configuration/conf/20001/color_info.json`,
        options
      );

      let file;
      try {
        file = await stream.json();
      } catch (e) {
        return;
      }

      colorObj.primary.code = file.primary;
      colorObj.secondary.code = file.secondary;

      this.setState({
        colors: colorObj,
      });
    };
  }

  componentDidMount() {
    this.props.loadSettings();
    this.setLogoUrl();
    this.setColors();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props))
      this.setState({
        'default-lang': this.props['default-lang'],
        'phone-prefix': this.props['phone-prefix'],
        'setup-file-url': this.props['setup-file-url'],
        domain: this.props.domain,
      });
  }

  render() {
    const hasSetupFile = this.state['setup-file-url'] !== undefined;
    const setupFileUrl = hasSetupFile ? this.state['setup-file-url'] : '';

    return (
      <main className="scrollArea">
        <h1 className="heading-section">GDPR Platform Setup</h1>
        <div className="row">
          <div className="col col--elastic gr-primary">
            <Form onSubmit={(e) => e.preventDefault()} standout>
              <SesamTextField
                fullWidth={false}
                label="Domain name"
                id="domain"
                type="input"
                value={this.state.domain}
                onChange={this.handleDomainChange}
                InputProps={{
                  endAdornment: this.props.sesamdata && <span>.sesamdata.com</span>,
                }}
                helperText={
                  <span>
                    This is the url that the end-users will use to see their GDPR data. Contact{' '}
                    <ExternalLink href={Links.MailToSupportAtSesamDotIO}>
                      Sesam Support
                    </ExternalLink>{' '}
                    if you want to use your own top level domain name.{' '}
                    <ExternalLink href={Links.PortalDomainNameDocumentation}>
                      Learn more.
                    </ExternalLink>
                  </span>
                }
              />

              {this.state.checkingDomain && (
                <div>
                  <InProgress /> Checking availability...
                </div>
              )}
              {this.state.domainAvailable && this.state.resolvedDomain && (
                <div className="notification-panel">{this.state.resolvedDomain} is available!</div>
              )}
              {!this.state.domainAvailable && this.state.resolvedDomain && (
                <div className="error-panel">{this.state.resolvedDomain} is not available!</div>
              )}
              <FormActions>
                <ActionBar>
                  {this.state.domain && this.props.sesamdata && (
                    <ExternalLink
                      href={`https://${this.state.domain}.sesamdata.com`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn--default"
                    >
                      Test Access Portal
                    </ExternalLink>
                  )}
                  <PromiseButton
                    disabled={!this.state.domainAvailable}
                    type="submit"
                    onClick={this.updateDomain}
                    pending="Setting domain..."
                  >
                    Set domain
                  </PromiseButton>
                </ActionBar>
              </FormActions>
            </Form>

            <Form onSubmit={this.upload} standout>
              <SesamTextField
                label="Upload data type template"
                id="type-upload"
                type="file"
                onChange={this.handleFileChange}
                helperText={
                  this.state.message ? (
                    this.state.message
                  ) : (
                    <span>
                      Before the end users/customers can start using your GDPR platform, you must
                      configure the data types, purposes and consents for your organisation.{' '}
                      <ExternalLink
                        href={Links.GdprDataTypesPurposeConfiguration}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        Learn more.
                      </ExternalLink>
                    </span>
                  )
                }
              />
              <FormActions>
                <Button type="submit">Upload data types</Button>
              </FormActions>
            </Form>

            <Form onSubmit={(e) => e.preventDefault()} standout>
              <SesamTextField
                label={`Setup file url (${hasSetupFile ? 'enabled' : 'disabled'})`}
                id="setup-file-url"
                type="input"
                value={setupFileUrl}
                onChange={this.handleChange}
                helperText={
                  <span>
                    If you have the setup file (see the Datatypes and purposes configuration
                    section) somewhere where it can be reached using an URL, you can configure the
                    GDPR platform to update the settings from this file at regular intervals.{' '}
                    <ExternalLink
                      href={Links.GdprPlatformConfiguration}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      Learn more.
                    </ExternalLink>
                  </span>
                }
              />
              <FormActions>
                <PromiseButton
                  type="submit"
                  onClick={this.setSetupFileUrl}
                  pending="Enabling url..."
                >
                  Enable setup url
                </PromiseButton>
                <PromiseButton
                  type="button"
                  onClick={this.removeSetupFileUrl}
                  pending="Disabling url..."
                >
                  Disable setup url
                </PromiseButton>
              </FormActions>
            </Form>

            <Form onSubmit={this.uploadDatabrowserLogo} standout>
              <img className="gdpr-config--databrowser-logo" alt="logo" src={this.state.logoPath} />
              <SesamTextField
                label="Upload new logo image"
                id="databrowser-logo-upload"
                type="file"
                onChange={this.handleDatabrowserLogoFileChange}
                margin="normal"
                helperText={this.state.databrowserLogoMessage}
              />
              <FormActions>
                <Button
                  disabled={this.state.uploadDatabrowserLogoInProgress === true}
                  type="submit"
                >
                  Upload logo
                </Button>
              </FormActions>
            </Form>

            <Form onSubmit={this.uploadColorInfo} standout>
              <div className="color-pickers">
                <div className="color-pickers__primary">
                  <label htmlFor="primary">Set primary color</label>
                  <ChromePicker
                    class="color-pickers__color-picker"
                    name="primary"
                    disableAlpha
                    onChangeComplete={(c) => {
                      this.handleColorChange(c, 'primary');
                    }}
                    color={this.state.colors.primary.code}
                  />
                </div>
                <div className="color-picker__secondary">
                  <label htmlFor="secondary">Set secondary color</label>
                  <ChromePicker
                    class="color-pickers__color-picker"
                    name="secondary"
                    disableAlpha
                    onChangeComplete={(c) => {
                      this.handleColorChange(c, 'secondary');
                    }}
                    color={this.state.colors.secondary.code}
                  />
                </div>
              </div>
              <FormActions>
                <ActionBar shortMessage={this.state.colors.message}>
                  <Button disabled={false} type="submit">
                    Update
                  </Button>
                </ActionBar>
              </FormActions>
            </Form>

            <Form onSubmit={(e) => e.preventDefault()} standout>
              <SesamTextField
                label="Default language"
                id="default-lang"
                type="input"
                value={this.state['default-lang']}
                onChange={this.handleChange}
                helperText={
                  <span>
                    The GDPR platform is configured with english and norwegian message text by
                    default. You can choose which default language is used by setting it to either a
                    full ISO country code (i.e. "country-dialect") or just the country code.{' '}
                    <ExternalLink
                      href={Links.GdprPlatformConfigurationDefaultLanguage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more.
                    </ExternalLink>
                  </span>
                }
              />
              <FormActions>
                <PromiseButton
                  type="submit"
                  onClick={this.setLanguage}
                  pending="Setting language..."
                >
                  Set language
                </PromiseButton>
              </FormActions>
            </Form>

            <Form onSubmit={(e) => e.preventDefault()} standout>
              <SesamTextField
                label="Phone prefix"
                id="phone-prefix"
                type="input"
                value={this.state['phone-prefix']}
                onChange={this.handleChange}
                helperText={
                  <span>
                    The phone prefix should be matched to your country, for example if your
                    organisation resides in Norway, it would look like <em>+47</em>
                  </span>
                }
              />
              <FormActions>
                <PromiseButton
                  type="submit"
                  onClick={this.setPhonePrefix}
                  pending="Setting prefix..."
                >
                  Set phone prefix
                </PromiseButton>
              </FormActions>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

GdprDataTypes.propTypes = {
  token: PropTypes.string.isRequired,
  gdprMicroserviceURL: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  setLanguageEnvironmentVariable: PropTypes.func.isRequired,
  setPhonePrefix: PropTypes.func.isRequired,
  setSetupFileUrl: PropTypes.func.isRequired,
  removeSetupFileUrl: PropTypes.func.isRequired,
  checkDomain: PropTypes.func.isRequired,
  updateDomain: PropTypes.func.isRequired,
  loadSettings: PropTypes.func.isRequired,
  sesamdata: PropTypes.bool.isRequired,
  'phone-prefix': PropTypes.string.isRequired,
  'default-lang': PropTypes.string.isRequired,
  databrowserURL: PropTypes.string.isRequired,
  'setup-file-url': PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const subData = state.subscriptions.find((s) => s.id === state.subscription.id);

  const databrowser = find(subData.connections, (i) => i.type === 'databrowser');

  // this ui edits the first custom domain
  const domainList = get(subData, ['products', 'gdpr_platform', 'custom_fqdn']);
  let domain = '';
  let sesamdata = true;
  if (domainList && domainList.length > 0) {
    domain = domainList[0];
    if (!domain.endsWith('.sesamdata.com')) {
      // don't break existing non sesamdata.com domains
      sesamdata = false;
    } else {
      domain = domain.replace('.sesamdata.com', '');
    }
  }

  return {
    'phone-prefix': state.env['default-country-phone-prefix'] || '',
    'default-lang': state.env['default-language'] || '',
    'setup-file-url': state.env['setup-file-url'],
    domain,
    sesamdata,
    token: state.subscription.token,
    gdprMicroserviceURL: getGdprMsUrl(state),
    databrowserURL: get(databrowser, 'url', ''),
  };
}

const mapDispatchToProps = (dispatch) => ({
  loadSettings: () => dispatch(EnvVarThunks.envLoadAll()),
  setLanguageEnvironmentVariable: (lang) =>
    dispatch(EnvVarThunks.envReplace('default-language', lang)),
  setPhonePrefix: (prefix) =>
    dispatch(EnvVarThunks.envReplace('default-country-phone-prefix', prefix)),
  setSetupFileUrl: (url) => dispatch(EnvVarThunks.envReplace('setup-file-url', url)),
  removeSetupFileUrl: () => dispatch(EnvVarThunks.envRemove('setup-file-url')),
  checkDomain: (domain) => dispatch(SubActions.checkDomain(domain)),
  updateDomain: (domain) => dispatch(SubActions.updateDomain(domain)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GdprDataTypes);
