import React from 'react';
import ExternalLink from 'Common/Links/ExternalLink';
import { Links } from 'Constants/links';

const DatasetInspectorViewerEmptyEntity = () => (
  <div className="dataset-inspector-viewer__empty-entity">
    <h1>
      Select a dataset entity from the list.
      <br />
      <ExternalLink
        target="_blank"
        rel="noopener noreferrer"
        href={Links.BuildingBlocksDatasetsDocumentation}
      >
        Read more about datasets.
      </ExternalLink>
    </h1>
  </div>
);

export default DatasetInspectorViewerEmptyEntity;
