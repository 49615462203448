import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'lodash/isEqual';
import { withStyles } from '@material-ui/core/styles';

import { getAvailableActions } from 'Internals/pipes';
import { confirmBefore } from 'Common/Confirmation';
import withUpload from 'Common/HOC/with-upload/WithUpload';
import Upload from 'Common/Upload/Upload';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import ExternalLink from 'Common/Links/ExternalLink';

import DownloadEntities from '../download-entities/DownloadEntities';
import MenuIcon from '../../images/icons/menu.svg';
import {
  MenuAction,
  MenuActionLink,
  MenuActionPopup,
  MenuActionPopupWithoutForm,
  MenuSeparator,
} from '../menu';
import MenuToggler from '../menu-toggler';
import { Links } from 'Constants/links';

import './style.css';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    color: theme.palette.text.primary,
  },
});

const JsonUpload = withUpload()(Upload);

class PipeActionsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { actionParameter: '' };
    this.availableActions = getAvailableActions(props.pipe);

    this.onParameterAction = (action, parameter) => {
      const parameters = {};
      if (this.state.actionParameter !== '') {
        parameters[parameter] = this.state.actionParameter;
      }
      this.onAction(action, parameters);
    };

    this.onAction = (action, parameters = {}) =>
      this.props.actionExecutor(this.props.pipe._id, action, parameters);

    /* NB: The `<input>` elements used to obtain the "actionParameter" cannot be
        controlled inputs, probably due to an unintended behaviour stemming from
        using `unstable_renderSubtreeIntoContainer` in the `MenuActionPopup`
        component. So we use a `ref` on those `<input>`s and keep them
        uncontrolled.

        This could probably be solved by using React 16's "Portals" feature.

        See IS-5122
    */

    this.onActionParameterChanged = (ev) => {
      this.setState({ actionParameter: ev.target.value });
    };

    this.resetActionParameter = () => {
      this.setState({ actionParameter: '' });
      if (this.actionParameterEl) {
        this.actionParameterEl.value = '';
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props))
      this.availableActions = getAvailableActions(this.props.pipe);
  }

  render() {
    const { classes } = this.props;
    const availableActions = this.availableActions;
    const menuIcon = <MenuIcon className="pipe-actions-menu" />;

    const pipeSource = get(this.props.pipe, 'config.effective.source.type');
    const pipeSink = get(this.props.pipe, 'config.effective.sink.type');

    return (
      <MenuToggler label={menuIcon} labelClassName="pipe-actions-menu__toggler">
        <MenuAction
          label="Copy pipe id"
          onClick={() => {
            navigator.clipboard.writeText(this.props.pipe._id);
            this.props.addToast('Copied to clipboard!');
          }}
        />
        <MenuAction
          disabled={!availableActions.includes('enable')}
          label="Enable"
          onClick={() => this.onAction('enable')}
        />
        <MenuActionPopup
          disabled={!availableActions.includes('disable')}
          label="Disable"
          onClick={() => this.onParameterAction('disable', 'comment')}
          onClose={this.resetActionParameter}
        >
          <SesamTextField
            inputProps={{ className: classes.root }}
            id="disable"
            onChange={this.onActionParameterChanged}
            ref={(el) => {
              this.actionParameterEl = el;
            }}
            helperText={
              <span className={classes.root}>
                You can add comment describing the reason for disabling this pipe. Leave blank for
                no comment.
              </span>
            }
          />
        </MenuActionPopup>
        <MenuSeparator />
        {!availableActions.includes('start-rescan') && (
          <MenuAction
            disabled={
              !availableActions.includes('update-last-seen') || !availableActions.includes('start')
            }
            label="Restart…"
            onClick={() => {
              confirmBefore('Caution! This will restart the pipe.', () => {
                this.onAction('update-last-seen', {
                  'last-seen': '',
                }).then(() => this.onAction('start'));
              });
            }}
          />
        )}
        <MenuAction
          disabled={!availableActions.includes('start')}
          label="Start"
          onClick={() => this.onAction('start')}
        />
        <MenuAction
          disabled={!availableActions.includes('stop')}
          label="Stop"
          onClick={() => this.onAction('stop')}
        />
        <MenuSeparator />
        <MenuAction
          disabled={!availableActions.includes('update-last-seen')}
          label="Reset…"
          onClick={() =>
            confirmBefore(`Caution! This will reset the pipe.`, () =>
              this.onAction('update-last-seen', { 'last-seen': '' })
            )
          }
        />
        <MenuAction
          disabled={!availableActions.includes('set-last-seen-to-end')}
          label="Reset to end…"
          onClick={() =>
            confirmBefore(
              'Danger! This will reset the pipe to end of the source dataset(s), which will usually result in inconsistent data!',
              () => this.onAction('set-last-seen-to-end')
            )
          }
        />
        <MenuActionPopup
          disabled={!availableActions.includes('update-last-seen')}
          label="Update last seen"
          onClick={() => this.onParameterAction('update-last-seen', 'last-seen')}
          onClose={this.resetActionParameter}
        >
          <SesamTextField
            inputProps={{ autoFocus: true, className: classes.root }}
            label="Last seen"
            autoFocus
            id="lastSeen"
            color="primary"
            defaultValue={get(this.props.pipe, 'runtime.last-seen', '')}
            onChange={this.onActionParameterChanged}
            ref={(el) => {
              this.actionParameterEl = el;
            }}
            helperText={
              <span className={classes.root}>
                See the{' '}
                <ExternalLink
                  href={Links.PumpUpdateLastSeenDocumentation}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  API documentation
                </ExternalLink>
              </span>
            }
          />
        </MenuActionPopup>
        <MenuAction
          disabled={!availableActions.includes('discard-retries')}
          label="Discard retry queue"
          onClick={() => this.onAction('discard-retries')}
        />
        <MenuAction
          disabled={!availableActions.includes('discard-inferred-schema')}
          label="Discard inferred schema"
          onClick={() => this.onAction('discard-inferred-schema')}
        />
        <MenuSeparator />
        <MenuAction
          disabled={!availableActions.includes('start-rescan')}
          label="Start rescan"
          onClick={() => this.onAction('start-rescan')}
        />
        <MenuAction
          disabled={!availableActions.includes('reset-rescan')}
          label="Reset rescan"
          onClick={() => this.onAction('reset-rescan')}
        />
        <MenuSeparator />
        <MenuActionLink
          label="Duplicate"
          to={`/subscription/${this.props.subId}/pipes/new/${this.props.pipe._id}`}
        />
        <MenuAction
          label="Delete…"
          onClick={() =>
            confirmBefore('Are you sure you want to delete this pipe?', () =>
              this.props.onDelete(this.props.pipe._id)
            )
          }
        />
        {pipeSource === 'http_endpoint' && (
          <React.Fragment>
            <MenuSeparator />
            <MenuActionPopupWithoutForm
              disabled={false}
              label="Upload to receiver"
              onClick={() => {
                // empty
              }}
              onClose={() => {
                // empty
              }}
            >
              <JsonUpload
                url={`${this.props.subUrl}/receivers/${encodeURIComponent(
                  this.props.pipe._id
                )}/entities`}
                token={this.props.token}
                allowed={['json']}
                editor
                curl
              />
            </MenuActionPopupWithoutForm>
          </React.Fragment>
        )}
        {pipeSink === 'csv_endpoint' && (
          <MenuActionPopupWithoutForm
            disabled={false}
            label="Download from publisher"
            onClick={() => {
              // empty
            }}
            onClose={() => {
              // empty
            }}
          >
            <DownloadEntities
              url={`${this.props.subUrl}/publishers/${encodeURIComponent(this.props.pipe._id)}/csv`}
              token={this.props.token}
              type="csv"
              pipeName={encodeURIComponent(this.props.pipe._id)}
              fileName={encodeURIComponent(this.props.pipe._id)}
              subId={this.props.subId}
            />
          </MenuActionPopupWithoutForm>
        )}
        {pipeSink === 'http_endpoint' && (
          <MenuActionPopupWithoutForm
            disabled={false}
            label="Download from publisher"
            onClick={() => {
              // empty
            }}
            onClose={() => {
              // empty
            }}
          >
            <DownloadEntities
              url={`${this.props.subUrl}/publishers/${encodeURIComponent(
                this.props.pipe._id
              )}/entities`}
              token={this.props.token}
              type="json"
              fileName={encodeURIComponent(this.props.pipe._id)}
              pipeName={encodeURIComponent(this.props.pipe._id)}
              subId={this.props.subId}
            />
          </MenuActionPopupWithoutForm>
        )}
        {pipeSink === 'xml_endpoint' && (
          <MenuActionPopupWithoutForm
            disabled={false}
            label="Download from publisher"
            onClick={() => {
              // empty
            }}
            onClose={() => {
              // empty
            }}
          >
            <DownloadEntities
              url={`${this.props.subUrl}/publishers/${encodeURIComponent(this.props.pipe._id)}/xml`}
              token={this.props.token}
              type="xml"
              fileName={encodeURIComponent(this.props.pipe._id)}
              pipeName={encodeURIComponent(this.props.pipe._id)}
              subId={this.props.subId}
            />
          </MenuActionPopupWithoutForm>
        )}
        {this.props.sinkDatasetID && (
          <div>
            <MenuSeparator />
            <MenuAction
              label="Delete sink dataset…"
              onClick={() =>
                confirmBefore("Are you sure you want to delete this pipe's sink dataset?", () =>
                  this.props.onDeleteSinkDataset(this.props.sinkDatasetID)
                )
              }
            />
            <MenuActionLink
              label="Create downstream pipe"
              to={`/subscription/${this.props.subId}/pipes/new?source=${this.props.sinkDatasetID}`}
            />
            <MenuAction label="Create outgoing flow" onClick={this.props.onOpenWizard} />
            <MenuSeparator />
          </div>
        )}
        {this.props.flowsLabel !== '' && (
          <MenuActionLink label={this.props.flowsLabel} to={this.props.flowsLink} />
        )}
      </MenuToggler>
    );
  }
}

PipeActionsMenu.propTypes = {
  actionExecutor: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteSinkDataset: PropTypes.func.isRequired,
  pipe: PropTypes.object.isRequired,
  subId: PropTypes.string.isRequired,
  subUrl: PropTypes.string.isRequired,
  sinkDatasetID: PropTypes.string,
  sourceDatasetID: PropTypes.string,
  token: PropTypes.string.isRequired,
  flowsLabel: PropTypes.string.isRequired,
  flowsLink: PropTypes.string.isRequired,
  onOpenWizard: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  addToast: PropTypes.func,
};

export default withStyles(styles)(PipeActionsMenu);
