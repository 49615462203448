import React from 'react';
import ExternalLink from 'Common/Links/ExternalLink';
import SesamLink from 'Common/Links/SesamLink';
import CenteredLayout from '../layout/centered';

import './server-error.css';
import { Links } from 'Constants/links';

function ServerError() {
  return (
    <CenteredLayout>
      <main className="server-error-page">
        <h2 className="heading-page">Server error</h2>
        <p>
          Uh-oh. Something unexpected just happened. Please <SesamLink to="/">try again</SesamLink>{' '}
          in about 30 seconds. If the problem continues to occur, contact us at{' '}
          <ExternalLink href={Links.MailToInfoAtSesamDotIO}>info@sesam.io</ExternalLink>
        </p>
      </main>
    </CenteredLayout>
  );
}

export default ServerError;
